/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Web3 from 'web3';
import NumberFormat from 'react-number-format';
import ReactGA from 'react-ga4';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import html2canvas from 'html2canvas';
import _ from 'lodash';

// Application Import
import AppConfig from './config';
import CollectionApi from './api/CollectionApi';
import { ICollectionObj } from './api/types/collection';

// Import Stylesheet
import './App.css';

// Import Icons
import GIF74UY from './assets/images/74Uy.gif';
import PreviewGif from './assets/images/preview.gif';
import JoinTheAcrocalypse from './assets/images/JoinTheAcrocalypse.png';
import JoinTheAcrocalypseMobile from './assets/images/JoinTheAcrocalypse-Mobile.png';
// import SpinnerIcon from './assets/images/icons/icon-spinner.svg';
import DiamondHand from './assets/images/rocket_hand.png';
import DiamondHandDiamond from './assets/images/diamond_hand.png';
import PaperHand from './assets/images/toilet_hand.png';
import CloseIcon from './assets/images/icons/icon-cross.png';
import TableChart from './assets/images/chart_hand.png';

import { DiamondhandReport, ReportSummary, ProfitSummary } from './AppTypes';

import PriceRangeTableComponent from './components/PriceRangeTableComponent';

function App() {
  const [currentTab, setCurrentTab] = useState<string>('paperHand');
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [searchAddress, setSearchAddress] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [step1ErrorMessage, setStep1ErrorMessage] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [reportSummary, setReportSummary] = useState<ReportSummary | null>(null);
  const [profitReportSummary, setProfitReportSummary] = useState<ProfitSummary | null>(null);
  const [diamondHandReportSummary, setDiamondHandReportSummary] = useState<DiamondhandReport | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [filterKey, setFilterKey] = useState<string>('collectionName');
  const [ethUsdtPrice, setEthUsdtPrice] = useState<number | null>(null);
  const [gasPrice, setGasPrice] = useState<string | null>(null);
  const [isAscSort, setIsAscSort] = useState<boolean>(true);
  const [collectionList, setCollectionList] = useState<Array<ICollectionObj>>([]);

  const filterKeyChangeHandler = (value: string) => {
    const sortDirection = filterKey === value ? (isAscSort ? 'desc' : 'asc') : 'asc';
    const sortedList = _.orderBy(collectionList, [value], [sortDirection]);
    setFilterKey(value);
    setIsAscSort(filterKey === value ? !isAscSort : true);

    setCollectionList(sortedList);
  };

  const fetchCollectionList = async () => {
    try {
      const response = await CollectionApi.getCollectionList();
      setCollectionList(response.data.data);
      setEthUsdtPrice(response.data.ethUsdtPrice);
      setGasPrice(response.data.gasPrice);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (walletAddress) {
      fetchCollectionList();
    }
  }, [walletAddress]);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (wrapperRef.current?.contains(e.target)) {
        return;
      }
      if (showModel) {
        setShowModel(false);
      }
    };

    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [showModel]);

  const submitSearchForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage('');
    setStep1ErrorMessage('');
    if (!searchAddress.length) {
      setStep1ErrorMessage('Please provide address');
      return;
    }

    setSearching(true);
    await loadManualAddresses(searchAddress);
    setSearching(false);
  };

  const loadManualAddresses = useCallback(async (address: string) => {
    let addresses: Array<string> = [];
    const webWalletAddress = address.toLowerCase();
    const walletAddresses = webWalletAddress.split(',');
    let invalidAddressFound = false;
    const web3 = new Web3(AppConfig.ALCHEMY_PROVIDER);

    for (let i = 0; i < walletAddresses.length; i++) {
      if (walletAddresses[i].indexOf('.eth') !== -1) {
        try {
          const resolvedAddress = await web3.eth.ens.getAddress(walletAddresses[i]);
          if (resolvedAddress) {
            addresses.push(resolvedAddress.toLowerCase());
          } else {
            invalidAddressFound = true;
          }
        } catch (error: any) {
          invalidAddressFound = true;
        }
      } else {
        if (!Web3.utils.isAddress(walletAddresses[i])) {
          invalidAddressFound = true;
          break;
        } else {
          addresses.push(walletAddresses[i]);
        }
      }
    }
    if (invalidAddressFound) {
      setStep1ErrorMessage('Invalid address provided');
    } else {
      setWalletAddress(addresses.join(','));
      for (let i = 0; i < addresses.length; i++) {
        trackAddress(addresses[i]);
      }
    }
  }, []);

  useEffect(() => {
    ReactGA.initialize(AppConfig.GA_MEASUREMENT_ID);
    ReactGA.send('pageview');

    const loadWalletAddress = async () => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const address = params.get('address');

      if (address === null) {
        if (window.hasOwnProperty('ethereum') && window.ethereum.hasOwnProperty('isMetaMask')) {
          try {
            const networkVersion = await window.ethereum.request({ method: 'net_version' });
            if (parseInt(networkVersion) !== 1) {
              setErrorMessage('Please connect to Ethereum network.');
              return;
            }

            const addresses = await window.ethereum.request({
              method: 'eth_requestAccounts',
            });
            const address = addresses[0];
            setWalletAddress(address);
            trackAddress(address);
          } catch (error: any) {
            // setErrorMessage('Error connecting to MetaMask. ' + error.message);
          }

          window.ethereum.on('accountsChanged', (accounts: any) => {
            if (accounts) {
              setWalletAddress(accounts[0]);
              trackAddress(accounts[0]);
            }
          });
        } else {
          // setErrorMessage(
          //   'Specify an address via ?address= in url GET parameter, or sign into MetaMask.'
          // );
        }
      } else {
        await loadManualAddresses(address);
      }
    };

    loadWalletAddress();
  }, [setWalletAddress, loadManualAddresses]);

  const trackAddress = (address: string) => {
    ReactGA.event({
      category: 'Tracking',
      action: 'load_address_transactions',
      label: address, // optional
      nonInteraction: true,
    });
  };

  useEffect(() => {
    // only proceed once the wallet address is set and is valid
    if (!walletAddress) {
      return;
    }

    const main = async () => {
      try {
        setLoading(true);

        // fetch record from server;
        const response = await axios.get(`${AppConfig.API_URL}/wallet-stats?walletAddress=${walletAddress}`);

        setReportSummary(response.data.paperhandsReportSummary);
        setProfitReportSummary(response.data.profitReportSummary);
        setDiamondHandReportSummary(response.data.diamondhandReportSummary);
      } catch (error: any) {
        toast.error(error.message, {
          autoClose: 5000,
        });
      } finally {
        setLoading(false);
      }
    };

    // start processing
    main();
  }, [walletAddress]);

  const footerContent = () => {
    return (
      <>
        version 1.4
        <br />
        crafted with ❤️
        <br />
        (say hello on{' '}
        <a href="https://twitter.com/iblamenfts" target="_blank" className="grey" rel="noreferrer">
          Twitter
        </a>
        )
        <div className="footer-social">
          <a href="https://twitter.com/iblamenfts" target="_blank" rel="noreferrer" className="footer-social-link">
            <span className="twitter-icon">
              <i className="sr-only">Twitter</i>
            </span>
          </a>
          <a href="https://discord.gg/7KZQNFMJrE" target="_blank" rel="noreferrer" className="footer-social-link">
            <span className="discord-icon">
              <i className="sr-only">Discord</i>
            </span>
          </a>
        </div>
      </>
    );
  };

  const sharePaperhandHandler = () => {
    const element = document.getElementById('paperhandContent');
    const modalBodyCanvas = document.getElementById('modalBodyCanvas');

    const options: any = {
      backgroundColor: '#000000',
      allowTaint: true,
      useCORS: true,
      windowWidth: 1600,
      scale: 1,
    };

    if (element && modalBodyCanvas) {
      html2canvas(element, options).then(function (canvas) {
        modalBodyCanvas.innerHTML = '';

        const newCanvas = document.createElement('canvas');
        const headerHeight = 200;
        const footerHeight = 238;
        const canvasHeight = canvas.height;

        newCanvas.width = canvas.width + 100;
        newCanvas.height = canvasHeight + headerHeight + footerHeight;
        newCanvas.id = 'canvasRef';

        // newCanvas.style.width = '100%';
        // newCanvas.style.height = 'auto';

        // newCanvas.style.maxHeight = '388px';
        // newCanvas.style.maxWidth = '100%';

        let destCtx = newCanvas.getContext('2d');
        if (destCtx) {
          destCtx.fillStyle = 'black';
          destCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);

          // draw top
          const base_image = new Image();
          base_image.src = 'canvas/canvas_top.png';
          base_image.onload = function () {
            const posX = Math.floor((newCanvas.width - 273) / 2);
            destCtx?.drawImage(base_image, posX, 50);
          };

          destCtx.drawImage(canvas, 80, headerHeight);

          // draw top
          const bottomImage = new Image();
          bottomImage.src = 'canvas/canvas_bottom.png';
          bottomImage.onload = function () {
            const posX = Math.floor((newCanvas.width - 350) / 2);
            destCtx?.drawImage(bottomImage, posX, canvasHeight + headerHeight + 30);
          };
        }

        modalBodyCanvas.appendChild(newCanvas);
      });

      setShowModel(true);
    }
  };

  const saveImageHandler = () => {
    const link = document.createElement('a');
    const imgSrc = document.getElementById('canvasRef') as HTMLCanvasElement;
    link.download = 'paperhand.png';
    link.href = imgSrc.toDataURL('image/png');
    link.click();
  };

  return (
    <>
      {walletAddress && (
        <>
          {/* For Desktop */}
          <a className="intro-banner" href="https://www.acrocalypse.gg/" target="_blank" rel="noreferrer">
            <img src={JoinTheAcrocalypse} alt="Paper Hand" className="img-fluid" />
          </a>

          {/* For Mobile */}
          <a className="intro-banner-mobile" href="https://www.acrocalypse.gg/" target="_blank" rel="noreferrer">
            <img src={JoinTheAcrocalypseMobile} alt="Paper Hand" className="img-fluid" />
          </a>

          {/* For Desktop */}
          {/* <a href="https://acrocalypse.gg" target="_blank" rel="noreferrer" className="ticker-block desktop-visible">
            <div className="ticker__element">
              <div className="ticker-wrap">
                <span className="ticker-text">
                  paperhands' ecosystem NFT acrocalypse is sold out! click here to see more
                </span>
              </div>
            </div>
          </a> */}

          {/* For Small Devices */}
          {/* <a href="https://acrocalypse.gg" target="_blank" rel="noreferrer" className="ticker-block mobile-visible">
            <div className="ticker__element">
              <div className="ticker-wrap">
                <span className="ticker-text">
                  paperhands' ecosystem NFT acrocalypse is sold out! click here to see more
                </span>
                <span className="ticker-text">
                  paperhands' ecosystem NFT acrocalypse is sold out! click here to see more
                </span>
                <span className="ticker-text">
                  paperhands' ecosystem NFT acrocalypse is sold out! click here to see more
                </span>
                <span className="ticker-text">
                  paperhands' ecosystem NFT acrocalypse is sold out! click here to see more
                </span>
                <span className="ticker-text">
                  paperhands' ecosystem NFT acrocalypse is sold out! click here to see more
                </span>
                <span className="ticker-text">
                  paperhands' ecosystem NFT acrocalypse is sold out! click here to see more
                </span>
                <span className="ticker-text">
                  paperhands' ecosystem NFT acrocalypse is sold out! click here to see more
                </span>
                <span className="ticker-text">
                  paperhands' ecosystem NFT acrocalypse is sold out! click here to see more
                </span>
              </div>
            </div>
          </a> */}
        </>
      )}
      <div className={showModel ? 'app-paperhanded modal-open' : 'app-paperhanded'}>
        {walletAddress && (
          <div className="app-paperhanded-controls">
            {/* <a href="https://www.acrocalypse.gg/" target="_blank" className="app-paperhanded-tear" rel="noreferrer">
              <img src={Tear} alt="Tear" className="img-fluid" />
            </a> */}
            <ul className="d-flex align-items-center list-none button-style">
              <li className={currentTab === 'paperHand' ? 'is--selected' : ''}>
                <button type="button" onClick={() => setCurrentTab('paperHand')}>
                  <img src={PaperHand} alt="Paper Hand" className="img-fluid rounded" />
                </button>
              </li>
              <li className={currentTab === 'diamondHand' ? 'is--selected' : ''}>
                <button type="button" onClick={() => setCurrentTab('diamondHand')}>
                  <img src={DiamondHandDiamond} alt="Diamond Hand" className="img-fluid rounded" />
                </button>
              </li>
              <li className={currentTab === 'profitReport' ? 'is--selected' : ''}>
                <button type="button" onClick={() => setCurrentTab('profitReport')}>
                  <img src={DiamondHand} alt="Profit Report" className="img-fluid rounded" />
                </button>
              </li>
              <li className={currentTab === 'priceRange' ? 'is--selected' : ''}>
                <button type="button" onClick={() => setCurrentTab('priceRange')}>
                  <img src={TableChart} alt="Price Range" className="img-fluid rounded" />
                </button>
              </li>
            </ul>
          </div>
        )}
        <div className="content">
          {errorMessage && <div className="error">{errorMessage}</div>}
          {!walletAddress && (
            <div className="content-inner">
              {/* <a href="https://www.acrocalypse.gg/" target="_blank" className="tear-graphic" rel="noreferrer">
                <img src={Tear} alt="Tear" className="img-fluid" />
              </a> */}
              <div className="content-inner-wrap">
                <div className="signin-text">
                  sign into{' '}
                  <a href="https://metamask.io/" target="_blank" className="grey" rel="noreferrer">
                    MetaMask
                  </a>{' '}
                  or search address
                </div>
                <form onSubmit={submitSearchForm}>
                  <div className="search-address">
                    {searching && (
                      <span className="icon">
                        <img src={GIF74UY} alt="Diamond Hand" className="img-fluid rounded" />
                      </span>
                    )}
                    {!searching && <span className="icon search-icon"></span>}
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchAddress(e.target.value)}
                      placeholder="Enter wallet address or ENS..."
                    />
                    {step1ErrorMessage && <div className="error">{step1ErrorMessage}</div>}
                  </div>
                </form>
                <a href="https://alpha.paperhands.gg" target="_blank" rel="noreferrer" className="mt-5 preview-image">
                  <img src={PreviewGif} alt="Paper Hand" className="img-fluid rounded" />
                </a>
              </div>
              <div className="signin-footer">
                {/* <div className="app-paperhanded-controls">
                  <ul className="d-flex align-items-center list-none button-style">
                    <li className="is--selected">
                      <button type="button" className="no-pointer">
                        <img src={PaperHand} alt="Paper Hand" className="img-fluid rounded" />
                      </button>
                    </li>
                    <li className="is--selected">
                      <button type="button" className="no-pointer">
                        <img src={DiamondHandDiamond} alt="Profit" className="img-fluid rounded" />
                      </button>
                    </li>
                    <li className="is--selected">
                      <button type="button" className="no-pointer">
                        <img src={DiamondHand} alt="Diamond Hand" className="img-fluid rounded" />
                      </button>
                    </li>
                  </ul>
                </div> */}
                {footerContent()}
              </div>
            </div>
          )}

          {walletAddress && currentTab === 'paperHand' && (
            <div id="paperhandContent">
              {!loading && walletAddress && reportSummary && parseFloat(reportSummary.totalLossInEth) === 0 ? (
                <div className="new-content">
                  <p className="text-center">
                    you've paperhanded a total of <span className="color-blue eth-price">Ξ0</span>.
                  </p>
                  {!loading && (
                    <div
                      data-html2canvas-ignore
                      className="w-full text-center d-flex align-items-center justify-content-center mt-5"
                    >
                      <div className="mx-auto">
                        <button onClick={sharePaperhandHandler} className="gradient-border">
                          share
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <React.Fragment>
                  <div className="new-content">
                    <p className="mb-2">
                      you've paperhanded a total of{' '}
                      <span className="color-blue">
                        {loading ? (
                          <>
                            <span className="size-48 ml-1">
                              <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="eth-price">Ξ{reportSummary?.totalLossInEth}</span>
                          </>
                        )}
                      </span>
                      .
                      <br />
                      right now that's worth{' '}
                      {loading ? (
                        <>💰👀</>
                      ) : (
                        <NumberFormat
                          className="color-blue eth-price"
                          value={reportSummary?.totalLossInUSD}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      )}
                      .
                    </p>
                    <p className="mb-2">
                      your biggest L?
                      <br />
                      {loading ? (
                        <>
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                        </>
                      ) : (
                        <>
                          {reportSummary && reportSummary.tokenImage && (
                            <img
                              src={reportSummary.tokenImage}
                              className="contract-image rounded"
                              crossOrigin="anonymous"
                            />
                          )}{' '}
                          <span className="color-blue">{reportSummary?.biggestLossCollectionName ?? ''}</span>
                        </>
                      )}
                      .
                    </p>
                    <p className="mb-2">
                      {loading && (
                        <>
                          you bought in at{' '}
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                          .
                          <br />
                        </>
                      )}
                      {!loading && reportSummary && reportSummary.transactionBuyPriceInEth && (
                        <>
                          you bought in at{' '}
                          <span className="color-blue eth-price">Ξ{reportSummary.transactionBuyPriceInEth}</span>{' '}
                          <NumberFormat
                            className="color-blue eth-price"
                            value={reportSummary.transactionBuyPriceInUSD}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'($'}
                            suffix={')'}
                          />
                          .
                          <br />
                          and{' '}
                        </>
                      )}
                      sold for{' '}
                      {loading ? (
                        <>
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="color-blue eth-price">Ξ{reportSummary?.transactionSoldPriceInEth}</span>{' '}
                          <NumberFormat
                            className="color-blue eth-price"
                            value={reportSummary?.transactionSoldPriceInUSD}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'($'}
                            suffix={')'}
                          />
                        </>
                      )}
                      .
                      {loading && (
                        <>
                          <br />
                          with a hodl time of{' '}
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                          .
                        </>
                      )}
                      {!loading && reportSummary?.transactionBuyPriceInEth && (
                        <>
                          <br />
                          with a hodl time of <span className="color-blue eth-price">
                            {reportSummary.daysHold}
                          </span>{' '}
                          days.
                        </>
                      )}
                    </p>
                    <p className="mb-2">
                      the current floor price is{' '}
                      {loading ? (
                        <>
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="color-blue eth-price">Ξ{reportSummary?.collectionFloorPriceInEth}</span>{' '}
                          <NumberFormat
                            className="color-blue eth-price"
                            value={reportSummary?.collectionFloorPriceInUSD}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'($'}
                            suffix={')'}
                          />
                        </>
                      )}
                      .
                    </p>
                    {loading ||
                      (!loading && reportSummary?.missedGainsPercent && (
                        <p className="d-inlineflex flex-wrap align-items-center mb-0 mt-1">
                          your missed gains are{' '}
                          {loading && (
                            <>
                              <span className="size-48 ml-1">
                                <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                              </span>
                            </>
                          )}
                          {!loading && reportSummary?.missedGainsPercent && (
                            <>
                              <span className="color-blue ml-2 eth-price">↓</span>
                              <NumberFormat
                                className="color-blue eth-price"
                                value={reportSummary?.missedGainsPercent}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={''}
                                suffix={'%'}
                              />
                            </>
                          )}
                          .
                        </p>
                      ))}
                    {loading ||
                      (!loading && reportSummary?.transactionBuyPriceInEth && (
                        <p>
                          <span className="color-blue">
                            {loading && (
                              <>
                                that's{' '}
                                <span className="size-48 ml-1">
                                  <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                                </span>
                              </>
                            )}
                            {!loading && reportSummary?.missedGainsInEth && (
                              <>
                                that's <span className="eth-price">Ξ{reportSummary?.missedGainsInEth}</span>
                              </>
                            )}
                          </span>{' '}
                          {loading ? (
                            <>or 👀💰</>
                          ) : (
                            <>
                              <NumberFormat
                                className="color-blue eth-price"
                                value={reportSummary?.missedGainsInUSD}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'($'}
                                suffix={')'}
                              />
                            </>
                          )}
                          .
                        </p>
                      ))}
                    {!loading && (
                      <div
                        data-html2canvas-ignore
                        className="w-full text-center d-flex align-items-center justify-content-center mt-5"
                      >
                        <div className="mx-auto">
                          <button onClick={sharePaperhandHandler} className="gradient-border">
                            share
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          )}

          {walletAddress && currentTab === 'profitReport' && (
            <>
              {!loading &&
              walletAddress &&
              profitReportSummary &&
              parseFloat(profitReportSummary.totalProfitInEth) === 0 ? (
                <div className="new-content">
                  <p className="text-center">
                    you've gained a total of <span className="color-green eth-price">Ξ0</span>.
                  </p>
                </div>
              ) : (
                <React.Fragment>
                  <div className="new-content">
                    <p className="mb-2">
                      you've gained a total of{' '}
                      <span className="color-green">
                        {loading ? (
                          <>
                            <span className="size-48 ml-1">
                              <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="eth-price">Ξ{profitReportSummary?.totalProfitInEth}</span>
                          </>
                        )}
                      </span>
                      .
                      <br />
                      right now that's worth{' '}
                      {loading ? (
                        <>💰👀</>
                      ) : (
                        <NumberFormat
                          className="color-green eth-price"
                          value={profitReportSummary?.totalProfitInUSD}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      )}
                      .
                    </p>
                    <p className="mb-2">
                      your biggest profit take yet?
                      <br />
                      {loading ? (
                        <>
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                        </>
                      ) : (
                        <>
                          {profitReportSummary?.tokenImage && (
                            <img
                              src={profitReportSummary.tokenImage}
                              alt={profitReportSummary.collectioName}
                              className="contract-image rounded"
                            />
                          )}{' '}
                          <span className="color-green">{profitReportSummary?.collectioName}</span>
                        </>
                      )}
                      .
                    </p>
                    <p className="mb-2">
                      {loading && (
                        <>
                          you bought in at{' '}
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                          .
                          <br />
                        </>
                      )}
                      {!loading && profitReportSummary?.transactionBuyPriceInEth && (
                        <>
                          you bought in at{' '}
                          <span className="color-green eth-price">
                            Ξ{profitReportSummary?.transactionBuyPriceInEth}
                          </span>{' '}
                          <NumberFormat
                            className="color-green eth-price"
                            value={profitReportSummary?.transactionBuyPriceInUSD}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'($'}
                            suffix={')'}
                          />
                          .
                          <br />
                          and{' '}
                        </>
                      )}
                      sold for{' '}
                      {loading ? (
                        <>
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="color-green eth-price">
                            Ξ{profitReportSummary?.transactionSoldPriceInEth}
                          </span>{' '}
                          <NumberFormat
                            className="color-green eth-price"
                            value={profitReportSummary?.transactionSoldPriceInUSD}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'($'}
                            suffix={')'}
                          />
                        </>
                      )}
                      .
                      {loading && (
                        <>
                          <br />
                          with a hodl time of{' '}
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                          .
                        </>
                      )}
                      {!loading && profitReportSummary?.daysHold && (
                        <>
                          <br />
                          with a hodl time of{' '}
                          <span className="color-green eth-price">{profitReportSummary.daysHold}</span> days.
                        </>
                      )}
                    </p>

                    {loading ||
                      (!loading && reportSummary?.transactionBuyPriceInEth && (
                        <>
                          <p className="d-inlineflex flex-wrap align-items-center mb-0 mt-1">
                            your total gains are{' '}
                            {loading ? (
                              <>
                                <span className="size-48 ml-1">
                                  <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="color-green ml-2 eth-price">↑</span>
                                <NumberFormat
                                  className="color-green eth-price"
                                  value={profitReportSummary?.totalGainsPercent}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={''}
                                  suffix={'%'}
                                />
                              </>
                            )}
                            .
                          </p>
                          <p>
                            that's{' '}
                            <span className="color-green">
                              {loading ? (
                                <>
                                  <span className="size-48 ml-1">
                                    <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                                  </span>
                                </>
                              ) : (
                                <span className="eth-price">Ξ{profitReportSummary?.totalGainsInEth}</span>
                              )}
                            </span>{' '}
                            {loading ? (
                              <>or 👀💰</>
                            ) : (
                              <>
                                <NumberFormat
                                  className="color-green eth-price"
                                  value={profitReportSummary?.totalGainsInUSD}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'($'}
                                  suffix={')'}
                                />
                              </>
                            )}
                            .
                          </p>
                        </>
                      ))}
                  </div>
                </React.Fragment>
              )}
            </>
          )}

          {walletAddress && currentTab === 'diamondHand' && (
            <>
              {!loading &&
              walletAddress &&
              diamondHandReportSummary &&
              parseFloat(diamondHandReportSummary.totalGainsInEth) === 0 ? (
                <div className="new-content">
                  <p className="text-center">
                    you're diamondhanding a total of <span className="color-green eth-price">Ξ0</span>.
                  </p>
                </div>
              ) : (
                <React.Fragment>
                  <div className="new-content">
                    <p className="mb-2">
                      you're diamondhanding a total of{' '}
                      <span className="color-green">
                        {loading ? (
                          <>
                            <span className="size-48 ml-1">
                              <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="eth-price">Ξ{diamondHandReportSummary?.totalGainsInEth}</span>
                          </>
                        )}
                      </span>
                      .
                      <br />
                      right now that's worth{' '}
                      {loading ? (
                        <>💰👀</>
                      ) : (
                        <NumberFormat
                          className="color-green eth-price"
                          value={diamondHandReportSummary?.totalGainsInUSD}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      )}
                      .
                    </p>
                    <p className="mb-2">
                      your biggest bag?
                      <br />
                      {loading ? (
                        <>
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                        </>
                      ) : (
                        <>
                          {diamondHandReportSummary?.tokenImage && (
                            <img
                              src={diamondHandReportSummary.tokenImage}
                              alt={diamondHandReportSummary.collectionName}
                              className="contract-image rounded"
                            />
                          )}{' '}
                          <span className="color-green">{diamondHandReportSummary?.collectionName}</span>
                        </>
                      )}
                      .
                    </p>
                    <p className="mb-2">
                      {loading && (
                        <>
                          you bought in at{' '}
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                          .
                          <br />
                        </>
                      )}
                      {!loading && diamondHandReportSummary?.boughtPriceInEth && (
                        <>
                          you bought in at{' '}
                          <span className="color-green eth-price">Ξ{diamondHandReportSummary?.boughtPriceInEth}</span>{' '}
                          <NumberFormat
                            className="color-green eth-price"
                            value={diamondHandReportSummary?.boughtPriceInUSD}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'($'}
                            suffix={')'}
                          />
                          .
                          <br />
                        </>
                      )}
                      the current floor price is{' '}
                      {loading ? (
                        <>
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="color-green eth-price">
                            Ξ{diamondHandReportSummary?.collectionFloorPriceInEth}
                          </span>{' '}
                          <NumberFormat
                            className="color-green eth-price"
                            value={diamondHandReportSummary?.collectionFloorPriceInUSD}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'($'}
                            suffix={')'}
                          />
                        </>
                      )}
                      .
                      {loading && (
                        <>
                          <br />
                          and you've been hodling for{' '}
                          <span className="size-48 ml-1">
                            <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                          </span>{' '}
                          days.
                        </>
                      )}
                      {!loading && diamondHandReportSummary?.daysHold && (
                        <>
                          <br />
                          and you’ve been hodling for{' '}
                          <span className="color-green eth-price">{diamondHandReportSummary?.daysHold}</span> days.
                        </>
                      )}
                    </p>

                    {loading ||
                      (!loading && diamondHandReportSummary?.gainsPercent && (
                        <>
                          <p className="d-inlineflex flex-wrap align-items-center mb-0 mt-1">
                            {loading ? <>that makes your current gain</> : <>which makes your gains</>}{' '}
                            {loading ? (
                              <>
                                <span className="size-48 ml-1">
                                  {' '}
                                  <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="color-green ml-2 eth-price">↑</span>
                                <NumberFormat
                                  className="color-green eth-price"
                                  value={diamondHandReportSummary?.gainsPercent}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={''}
                                  suffix={'%'}
                                />
                              </>
                            )}
                            .
                          </p>
                          <p className="">
                            that's{' '}
                            <span className="color-green">
                              {loading ? (
                                <>
                                  <span className="size-48 ml-1">
                                    <img src={GIF74UY} alt="loading" className="img-fluid rounded" />
                                  </span>
                                </>
                              ) : (
                                <span className="eth-price">Ξ{diamondHandReportSummary?.gainInEth}</span>
                              )}
                            </span>{' '}
                            {loading ? (
                              <>or 👀💰</>
                            ) : (
                              <>
                                <NumberFormat
                                  className="color-green eth-price"
                                  value={diamondHandReportSummary?.gainInUSD}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'($'}
                                  suffix={')'}
                                />
                              </>
                            )}
                            .
                          </p>
                        </>
                      ))}
                  </div>
                </React.Fragment>
              )}
            </>
          )}

          {walletAddress && currentTab === 'priceRange' && (
            <PriceRangeTableComponent
              collectionList={collectionList}
              filterKeyChangeHandler={filterKeyChangeHandler}
              filterKey={filterKey}
              isAscSort={isAscSort}
              ethUsdtPrice={ethUsdtPrice}
              gasPrice={gasPrice}
            />
          )}
        </div>
        {walletAddress && <div className="footer">{footerContent()}</div>}
        <ToastContainer />

        <div id="open-modal" className="modal-window">
          <div className="modal-content" ref={wrapperRef}>
            <div className="modal-close">
              <img src={CloseIcon} alt="Close" className="img-fluid" onClick={() => setShowModel(false)} />
            </div>
            <div className="modal-body">
              <div style={{ textAlign: 'center' }} id="modalBodyCanvas"></div>
            </div>

            <div className="w-full text-center d-flex align-items-center justify-content-center flex-column mt-2">
              <div className="mx-auto mb-4">
                <button type="button" className="gradient-border" onClick={saveImageHandler}>
                  Download
                </button>
              </div>
              <div className="mx-auto">
                <a
                  href={`http://www.twitter.com/share?text=So far, I’ve paperhanded ${reportSummary?.totalLossInEth} ETH. Think you got me beat? Find out with paperhands.gg %23paperhands 🧻🙌`}
                  className="gradient-border lg mb-12"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="twitter-icon mr-2">
                    <i className="sr-only">Twitter</i>
                  </span>
                  Share on Twitter
                </a>
                <a
                  href="https://discord.gg/acrocalypse"
                  target="_blank"
                  rel="noreferrer"
                  className="gradient-border lg"
                >
                  <span className="discord-icon mr-2">
                    <i className="sr-only">Discord</i>
                  </span>
                  Join on Discord
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
