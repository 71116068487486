import { AxiosResponse } from 'axios';
import BaseApi from './BaseApi';
import { ICollectionListResponse } from './types/collection';

const CollectionApi = {
  getCollectionList: async (): Promise<AxiosResponse<ICollectionListResponse>> => {
    return await BaseApi.getRequest(`/collections`);
  },
};

export default CollectionApi;
