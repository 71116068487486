const AppConfig = {
  API_URL: process.env.REACT_APP_API_URL || '',
  ETHERSCAN_APIKEY: process.env.REACT_APP_ETHERSCAN_APIKEY || '',
  OPENSEA_APIKEY: process.env.REACT_APP_OPENSEA_APIKEY || '',
  ALCHEMY_PROVIDER: process.env.REACT_APP_ALCHEMY_PROVIDER || '',
  CONTRACT_ADDRESSES: {
    '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb': {
      name: 'CryptoPunks',
    },
    '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d': {
      name: 'Bored Ape Yacht Club',
    },
    '0xd07dc4262bcdbf85190c01c996b4c06a461d2430': {
      name: 'the#boi',
    },
    '0x60e4d786628fea6478f785a6d7e704777c86a7c6': {
      name: 'Mutant Ape Yacht Club',
    },
    '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7': {
      name: 'Meebits',
    },
    '0x06012c8cf97bead5deae237070f9587f8e7a266d': {
      name: 'CryptoKitties',
    },
    '0xff9c1b15b16263c61d017ee9f65c50e4ae0113d7': {
      name: 'Loot (for Adventurers)',
    },
    '0xf87e31492faf9a91b02ee0deaad50d51d56d5d4d': {
      name: 'Decentraland',
    },
    '0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0': {
      name: 'SuperRare',
    },
    '0x629a673a8242c2ac4b7b8c5d8735fbeac21a6205': {
      name: 'Sorare',
    },
    '0x1a92f7381b9f03921564a437210bb9396471050c': {
      name: 'Cool Cats NFT',
    },
    '0x3bf2922f4520a8ba0c2efc3d2a1539678dad5e9d': {
      name: '0N1 Force',
    },
    '0xbd3531da5cf5857e7cfaa92426877b022e612cf8': {
      name: 'Pudgy Penguins',
    },
    '0x76be3b62873462d2142405439777e971754e8e77': {
      name: 'Parallel Alpha',
    },
    '0x1cb1a5e65610aeff2551a50f76a87a7d3fb649c6': {
      name: 'CrypToadz by GREMPLIN',
    },
    '0xc2c747e0f7004f9e8817db2ca4997657a7746928': {
      name: 'Hashmasks',
    },
    '0x5ab21ec0bfa0b29545230395e3adaca7d552c948': {
      name: 'PUNKS Comic',
    },
    '0x9a534628b4062e123ce7ee2222ec20b86e16ca8f': {
      name: 'MekaVerse',
    },
    '0xba30e5f9bb24caa003e9f2f0497ad287fdf95623': {
      name: 'Bored Ape Kennel Club',
    },
    '0x73da73ef3a6982109c4d5bdb0db9dd3e3783f313': {
      name: 'My Curio Cards',
    },
    '0x50f5474724e0ee42d9a4e711ccfb275809fd6d4a': {
      name: 'The Sandbox',
    },
    '0xa3aee8bce55beea1951ef834b99f3ac60d1abeeb': {
      name: 'VeeFriends',
    },
    '0x57a204aa1042f6e66dd7730813f4024114d74f37': {
      name: 'CyberKongz',
    },
    '0xf5b0a3efb8e8e4c201e2a935f110eaaf3ffecb8d': {
      name: 'Axie Infinity',
    },
    '0x77bd275ff2b3dc007475aac9ce7f408f5a800188': {
      name: 'MyCryptoHeroes',
    },
    '0xc92ceddfb8dd984a89fb494c376f9a48b999aafc': {
      name: 'Creature World',
    },
    '0xe785e82358879f061bc3dcac6f0444462d4b5330': {
      name: 'World of Women',
    },
    '0x82c7a8f707110f5fbb16184a5933e9f78a34c6ab': {
      name: 'Emblem Vault [Ethereum]',
    },
    '0x913ae503153d9a335398d0785ba60a2d63ddb4e2': {
      name: 'Somnium Space VR',
    },
    '0x4b3406a41399c7fd2ba65cbc93697ad9e7ea61e5': {
      name: 'LOSTPOETS',
    },
    '0x22c36bfdcef207f9c0cc941936eff94d4246d14a': {
      name: 'Bored Ape Chemistry Club',
    },
    '0x0e3a2a1f2146d86a604adc220b4967a898d7fe07': {
      name: 'Gods Unchained',
    },
    '0xf4ee95274741437636e748ddac70818b4ed7d043': {
      name: 'The Doge Pound',
    },
    '0x32b7495895264ac9d0b12d32afd435453458b1c6': {
      name: 'Decentraland Wearables',
    },
    '0xd4e4078ca3495de5b1d4db434bebc5a986197782': {
      name: 'Autoglyphs',
    },
    '0x219b8ab790decc32444a6600971c7c3718252539': {
      name: 'Sneaky Vampire Syndicate',
    },
    '0x7ea3cca10668b8346aec0bf1844a49e995527c8b': {
      name: 'CyberKongz VX',
    },
    '0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0': {
      name: 'Lazy Lions',
    },
    '0x3fe1a4c1481c8351e91b64d5c398b159de07cbc5': {
      name: 'SupDucks',
    },
    '0xccc441ac31f02cd96c153db6fd5fe0a2f4e6a68d': {
      name: 'FLUF World',
    },
    '0xaadc2d4261199ce24a4b0a57370c4fcf43bb60aa': {
      name: 'Damien Hirst - The Currency',
    },
    '0xedb61f74b0d09b2558f1eeb79b247c1f363ae452': {
      name: 'Gutter Cat Gang',
    },
    '0x12d2d1bed91c24f878f37e66bd829ce7197e4d14': {
      name: 'GalacticApes',
    },
    '0xa08126f5e1ed91a635987071e6ff5eb2aeb67c48': {
      name: 'Galaxy-Eggs',
    },
    '0xc3f733ca98e0dad0386979eb96fb1722a1a05e69': {
      name: 'MoonCats',
    },
    '0x3f5fb35468e9834a43dca1c160c69eaae78b6360': {
      name: 'Koala Intelligence Agency',
    },
    '0xf497253c2bb7644ebb99e4d9ecc104ae7a79187a': {
      name: 'The Sevens (Official)',
    },
    '0x36d30b3b85255473d27dd0f7fd8f35e36a9d6f06': {
      name: '888 inner circle',
    },
    '0xbad6186e92002e312078b5a1dafd5ddf63d3f731': {
      name: 'Anonymice',
    },
    '0xaadba140ae5e4c8a9ef0cc86ea3124b446e3e46a': {
      name: 'MutantCats',
    },
    '0x11595ffb2d3612d810612e34bc1c2e6d6de55d26': {
      name: 'Tom Sachs Rocket Factory',
    },
    '0x7ab2352b1d2e185560494d5e577f9d3c238b78c5': {
      name: 'Adam Bomb Squad',
    },
    '0x05a46f1e545526fb803ff974c790acea34d1f2d6': {
      name: 'The n project',
    },
    '0xecdd2f733bd20e56865750ebce33f17da0bee461': {
      name: 'The CryptoDads',
    },
    '0xdd69da9a83cedc730bc4d3c56e96d29acc05ecde': {
      name: 'TIMEPieces Build a Better Future: Genesis Drop',
    },
    '0x4f89cd0cae1e54d98db6a80150a824a533502eea': {
      name: 'PEACEFUL GROUPIES',
    },
    '0xb7f7f6c52f2e2fdb1963eab30438024864c313f6': {
      name: 'Wrapped Cryptopunks',
    },
    '0x3a5051566b2241285be871f650c445a88a970edd': {
      name: 'The Humanoids',
    },
    '0x18df6c571f6fe9283b87f910e41dc5c8b77b7da5': {
      name: 'Animetas',
    },
    '0x1ca39c7f0f65b4da24b094a9afac7acf626b7f38': {
      name: 'GEN.ART Membership',
    },
    '0x4f8730e0b32b04beaa5757e5aea3aef970e5b613': {
      name: 'Bloot (not for Weaks)',
    },
    '0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63': {
      name: 'Blitmap',
    },
    '0x85f740958906b317de6ed79663012859067e745b': {
      name: 'The Wicked Craniums',
    },
    '0xef0182dc0574cd5874494a120750fd222fdb909a': {
      name: 'Rumble Kong League',
    },
    '0x099689220846644f87d1137665cded7bf3422747': {
      name: 'Robotos Official',
    },
    '0x34b4df75a17f8b3a6eff6bba477d39d701f5e92c': {
      name: 'GEVOLs',
    },
    '0x18c7766a10df15df8c971f6e8c1d2bba7c7a410b': {
      name: 'The Vogu Collective',
    },
    '0xc8bcbe0e8ae36d8f9238cd320ef6de88784b1734': {
      name: 'Winter Bears',
    },
    '0x7afeda4c714e1c0a2a1248332c100924506ac8e6': {
      name: 'FVCK_CRYSTAL//',
    },
    '0x1b829b926a14634d36625e60165c0770c09d02b2': {
      name: 'Treeverse',
    },
    '0x7c40c393dc0f283f318791d746d894ddd3693572': {
      name: 'Wrapped MoonCatsRescue - Unofficial',
    },
    '0x31385d3520bced94f77aae104b406994d8f2168c': {
      name: 'BASTARD GAN PUNKS V2',
    },
    '0xbe6e3669464e7db1e1528212f0bff5039461cb82': {
      name: 'Wicked Ape Bone Club',
    },
    '0xad9fd7cb4fc7a0fbce08d64068f60cbde22ed34c': {
      name: 'Vox Collectibles',
    },
    '0xd4d871419714b778ebec2e22c7c53572b573706e': {
      name: 'Stoner Cats',
    },
    '0xabb3738f04dc2ec20f4ae4462c3d069d02ae045b': {
      name: 'KnownOrigin',
    },
    '0x8cd8155e1af6ad31dd9eec2ced37e04145acfcb3': {
      name: 'Cupcats Official',
    },
    '0x2acab3dea77832c09420663b0e1cb386031ba17b': {
      name: 'DeadFellaz',
    },
    '0x6dc6001535e15b9def7b0f6a20a2111dfa9454e2': {
      name: 'MetaHero Universe: Generative Identities',
    },
    '0xd754937672300ae6708a51229112de4017810934': {
      name: 'Deafbeef',
    },
    '0x10daa9f4c0f985430fde4959adb2c791ef2ccf83': {
      name: 'The Meta Key',
    },
    '0x0c2e57efddba8c768147d1fdf9176a0a6ebd5d83': {
      name: 'Kaiju Kingz',
    },
    '0xce25e60a89f200b1fa40f6c313047ffe386992c3': {
      name: 'dotdotdots',
    },
    '0x8ca5209d8cce34b0de91c2c4b4b14f20aff8ba23': {
      name: 'Al Cabones',
    },
    '0x8a1bbef259b00ced668a8c69e50d92619c672176': {
      name: 'Habbo Avatars',
    },
    '0xbace7e22f06554339911a03b8e0ae28203da9598': {
      name: 'CryptoArte',
    },
    '0x7f72528229f85c99d8843c0317ef91f4a2793edf': {
      name: '1111 by Kevin Abosch',
    },
    '0xc170384371494b2a8f6ba20f4d085c4dde763d96': {
      name: 'BEEPLE: EVERYDAYS - THE 2020 COLLECTION',
    },
    '0xe4605d46fd0b3f8329d936a8b258d69276cba264': {
      name: 'FEWOCiOUS',
    },
    '0xbea8123277142de42571f1fac045225a1d347977': {
      name: 'DystoPunks',
    },
    '0x9f9c171afde4cc6bbf6d38ae4012c83633653b85': {
      name: 'PGFK',
    },
    '0x2af75676692817d85121353f0d6e8e9ae6ad5576': {
      name: 'F1\u00ae Delta Time',
    },
    '0x3f4a885ed8d9cdf10f3349357e3b243f3695b24a': {
      name: 'Incognito',
    },
    '0x181aea6936b407514ebfc0754a37704eb8d98f91': {
      name: 'yInsure NFT',
    },
    '0x343f999eaacdfa1f201fb8e43ebb35c99d9ae0c1': {
      name: 'Lonely Alien Space Club',
    },
    '0x4d3814d4da8083b41861dec2f45b4840e8b72d68': {
      name: 'CryptoSpaceCommanders',
    },
    '0x86357a19e5537a8fba9a004e555713bc943a66c0': {
      name: 'Neo Tokyo Identities',
    },
    '0x236672ed575e1e479b8e101aeeb920f32361f6f9': {
      name: 'Frontier Game',
    },
    '0x1d20a51f088492a0f1c57f047a9e30c9ab5c07ea': {
      name: 'loomlocknft (Wassies)',
    },
    '0x40fb1c0f6f73b9fc5a81574ff39d27e0ba06b17b': {
      name: 'The Mike Tyson NFT Collection by Cory Van Lew',
    },
    '0x6fc355d4e0ee44b292e50878f49798ff755a5bbc': {
      name: 'DeadHeads',
    },
    '0x3a8778a58993ba4b941f85684d74750043a4bb5f': {
      name: 'BullsOnTheBlock',
    },
    '0xdfacd840f462c27b0127fc76b63e7925bed0f9d5': {
      name: 'Avid Lines',
    },
    '0x90ca8a3eb2574f937f514749ce619fdcca187d45': {
      name: 'Gambling Apes',
    },
    '0xfb61bd914d4cd5509ecbd4b16a0f96349e52db3d': {
      name: 'REMIX! Club',
    },
    '0x9c57d0278199c931cf149cc769f37bb7847091e7': {
      name: 'Sipherian Surge',
    },
    '0x3702f4c46785bbd947d59a2516ac1ea30f2babf2': {
      name: 'Galaxy Fight Club',
    },
    '0xfcb1315c4273954f74cb16d5b663dbf479eec62e': {
      name: 'Capsule House',
    },
    '0xb6dae651468e9593e4581705a09c10a76ac1e0c8': {
      name: 'Async Art',
    },
    '0xc22616e971a670e72f35570337e562c3e515fbfe': {
      name: 'Playing Arts Crypto Edition',
    },
    '0x454cbc099079dc38b145e37e982e524af3279c44': {
      name: 'The Yakuza Cats Society',
    },
    '0x9261b6239a85348e066867c366d3942648e24511': {
      name: 'Monkey Bet DAO',
    },
    '0x495f947276749ce646f68ac8c248420045cb7b5e': {
      name: 'Noora Health Save Thousands of Lives',
    },
    '0x9d418c2cae665d877f909a725402ebd3a0742844': {
      name: 'Fang Gang',
    },
    '0xd7b397edad16ca8111ca4a3b832d0a5e3ae2438c': {
      name: 'Gutter Rats',
    },
    '0xc9cb0fee73f060db66d2693d92d75c825b1afdbf': {
      name: 'First First NFTs',
    },
    '0xe3782b8688ad2b0d5ba42842d400f7adf310f88d': {
      name: 'BCCG',
    },
    '0xafb44cef938b1be600a4331bf9904f6cec2fcac3': {
      name: 'EllioTrades NFT Collection',
    },
    '0x521f9c7505005cfa19a8e5786a9c3c9c9f5e6f42': {
      name: 'Forgotten Runes Wizards Cult',
    },
    '0xe3435edbf54b5126e817363900234adfee5b3cee': {
      name: 'Voxies',
    },
    '0x909899c5dbb5002610dd8543b6f638be56e3b17e': {
      name: 'Plasma Bears',
    },
    '0x1eff5ed809c994ee2f500f076cef22ef3fd9c25d': {
      name: 'PEGZ',
    },
    '0xf3e778f839934fc819cfa1040aabacecba01e049': {
      name: 'Avastars',
    },
    '0xf7143ba42d40eaeb49b88dac0067e54af042e963': {
      name: 'Metasaurs by Dr. DMT',
    },
    '0xa406489360a47af2c74fc1004316a64e469646a5': {
      name: 'The Official Surreals',
    },
    '0x2d0ee46b804f415be4dc8aa1040834f5125ebd2e': {
      name: 'Dapper Dinos NFT',
    },
    '0x8a939fd297fab7388d6e6c634eee3c863626be57': {
      name: 'XCOPY',
    },
    '0x1897d69cc0088d89c1e94889fbd2efffcefed778': {
      name: 'Gutter Species Mint Pass',
    },
    '0x47e22659d9ae152975e6cbfa2eed5dc8b75ac545': {
      name: 'Fortune Media',
    },
    '0x7e6bc952d4b4bd814853301bee48e99891424de0': {
      name: 'JUNGLE FREAKS BY TROSLEY',
    },
    '0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270': {
      name: 'Elementals by Michael Connolly',
    },
    '0x21850dcfe24874382b12d05c5b189f5a2acf0e5b': {
      name: 'The KILLAZ',
    },
    '0xb9341cca0a5f04b804f7b3a996a74726923359a8': {
      name: 'OFF BLUE',
    },
    '0x7d9d3659dcfbea08a87777c52020bc672deece13': {
      name: 'Ether Orcs Legacy Page',
    },
    '0x42069abfe407c60cf4ae4112bedead391dba1cdb': {
      name: 'CryptoDickbutts',
    },
    '0xce50f3ca1f1dbd6fa042666bc0e369565dda457d': {
      name: 'TheHeartProject',
    },
    '0xb159f1a0920a7f1d336397a52d92da94b1279838': {
      name: 'Royal Society of Players',
    },
    '0x2d3f39663d43c0862589a8a24bf05ccd44b0ac4d': {
      name: 'StackedToadz',
    },
    '0x6317c4cab501655d7b85128a5868e98a094c0082': {
      name: 'MonsterBuds',
    },
    '0x85f0e02cb992aa1f9f47112f815f519ef1a59e2d': {
      name: 'Polychain Monsters',
    },
    '0x8184a482a5038b124d933b779e0ea6e0fb72f54e': {
      name: 'The Wanderers',
    },
    '0x8bf2f876e2dcd2cae9c3d272f325776c82da366d': {
      name: 'Extension Loot (for Adventurers)',
    },
    '0x80f1ed6a1ac694317dc5719db099a440627d1ea7': {
      name: 'IKB Cachet de Garantie',
    },
    '0x8cb813bf27dc744fc5fb6ba7515504de45d39e08': {
      name: 'PolkaPets TCG',
    },
    '0xb5f3dee204ca76e913bb3129ba0312b9f0f31d82': {
      name: 'Omnimorphs',
    },
    '0xda9f43015749056182352e9dc6d3ee0b6293d80a': {
      name: 'EtherLambos',
    },
    '0x364c828ee171616a39897688a831c2499ad972ec': {
      name: 'Sappy Seals',
    },
    '0x8c9b261faef3b3c2e64ab5e58e04615f8c788099': {
      name: 'MLB Champions',
    },
    '0xf621b26ce64ed28f42231bcb578a8089f7958372': {
      name: 'Bored Mummy Waking Up',
    },
    '0x20c70bdfcc398c1f06ba81730c8b52ace3af7cc3': {
      name: 'Mutant Garden Seeder',
    },
    '0x0ee24c748445fb48028a74b0ccb6b46d7d3e3b33': {
      name: 'NAH FUNGIBLE BONES',
    },
    '0x31f3bba9b71cb1d5e96cd62f0ba3958c034b55e9': {
      name: 'Party Penguins',
    },
    '0xaa6612f03443517ced2bdcf27958c22353ceeab9': {
      name: 'Bamboozlers',
    },
    '0x80a4b80c653112b789517eb28ac111519b608b19': {
      name: 'Crypto Cannabis Club',
    },
    '0x70732c08fb6dbb06a64bf619c816c22aed12267a': {
      name: 'NFTBoxes',
    },
    '0x0bb3e1c80c0ffde985fb0ec6392aa18a1d2de40e': {
      name: 'Fatales',
    },
    '0x1710d860034b50177d793e16945b6a25c7d92476': {
      name: 'ZombieToadz',
    },
    '0xaa20f900e24ca7ed897c44d92012158f436ef791': {
      name: 'Eponym by ART AI',
    },
    '0x74ecb5f64363bd663abd3ef08df75dd22d853bfc': {
      name: 'Gauntlets',
    },
    '0x8630cdeaa26d042f0f9242ca30229b425e7f243f': {
      name: 'Claylings',
    },
    '0x8707276df042e89669d69a177d3da7dc78bd8723': {
      name: 'Dope Wars',
    },
    '0x2216d47494e516d8206b70fca8585820ed3c4946': {
      name: 'Waifusion',
    },
    '0x80416304142fa37929f8a4eee83ee7d2dac12d7c': {
      name: 'Generativemasks',
    },
    '0xbc4ca343167a5e2d9f700cf5b6b3f849585cd6fc': {
      name: 'The 140 Collection by Twitter',
    },
    '0xc6735852e181a55f736e9db62831dc63ef8c449a': {
      name: 'Rogue Society Bots',
    },
    '0xa7ee407497b2aeb43580cabe2b04026b5419d1dc': {
      name: 'The Superlative Secret Society',
    },
    '0x7cba74d0b16c8e18a9e48d3b7404d7739bb24f23': {
      name: 'FoxFam',
    },
    '0x15a2d6c2b4b9903c27f50cb8b32160ab17f186e2': {
      name: 'GOOP TROOP',
    },
    '0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8': {
      name: 'Ether Cards Founder',
    },
    '0x8442dd3e5529063b43c69212d64d5ad67b726ea6': {
      name: 'Goons of Balatroon',
    },
    '0x49ac61f2202f6a2f108d59e77535337ea41f6540': {
      name: 'NFT Siblings Avatars',
    },
    '0xf36446105ff682999a442b003f2224bcb3d82067': {
      name: 'Axolittles',
    },
    '0x1981cc36b59cffdd24b01cc5d698daa75e367e04': {
      name: 'Crypto.Chicks',
    },
    '0xccee4d557588e982ecf3a2391d718c76589d8af9': {
      name: 'CryptoZoo.co',
    },
    '0xdda32aabbbb6c44efc567bac5f7c35f185338456': {
      name: 'Cypher by Hideki Tsukamoto',
    },
    '0x050dc61dfb867e0fe3cf2948362b6c0f3faf790b': {
      name: 'PixelMap',
    },
    '0xe885d519025348e30174a218be3e3e4754222860': {
      name: 'Woodies Mint Passport',
    },
    '0x4a8b01e437c65fa8612e8b699266c0e0a98ff65c': {
      name: 'Space Poggers',
    },
    '0x23cd7da31501aa54f61384a3756fb4a1329dbb2c': {
      name: 'BEEPLE - THE 5000 DAYS COLLECTION',
    },
    '0x4ad4455ad5ef891695c221e8e683efa65fabede0': {
      name: 'Bullrun Babes',
    },
    '0x08f0b2a4351514e63e9e03a661adfe58d463cfbc': {
      name: 'The Project URS',
    },
    '0xcdb7c1a6fe7e112210ca548c214f656763e13533': {
      name: 'Ready Player Cat NFT',
    },
    '0x11450058d796b02eb53e65374be59cff65d3fe7f': {
      name: 'THE SHIBOSHIS',
    },
    '0x960b7a6bcd451c9968473f7bbfd9be826efd549a': {
      name: 'OnChainMonkey',
    },
    '0xf3e6dbbe461c6fa492cea7cb1f5c5ea660eb1b47': {
      name: 'FameLadySquad',
    },
    '0x1db61fc42a843bad4d91a2d788789ea4055b8613': {
      name: 'Chubbies',
    },
    '0x86f6bf16f495afc065da4095ac12ccd5e83a8c85': {
      name: 'Crazy Lizard Army',
    },
    '0x80adb36595239fe918c7d118c1f81e07d070801a': {
      name: 'HeavenComputer',
    },
    '0xdd467a6c8ae2b39825a452e06b4fa82f73d4253d': {
      name: 'Mars Cats Voyage',
    },
    '0xc9e2c9718ff7d3129b9ac12168195507e4275cea': {
      name: '0xVampire Project',
    },
    '0x2250d7c238392f4b575bb26c672afe45f0adcb75': {
      name: 'FEWOCiOUS x RTFKT',
    },
    '0xd31fc221d2b0e0321c43e9f6824b26ebfff01d7d': {
      name: 'Brotchain',
    },
    '0x772c9181b0596229ce5ba898772ce45188284379': {
      name: 'Sad Frogs District',
    },
    '0xb5c747561a185a146f83cfff25bdfd2455b31ff4': {
      name: 'Boss Beauties',
    },
    '0xd153f0014db6d1f339c6340d2c9f59214355d9d7': {
      name: 'Crypto Hobos',
    },
    '0x5754f44bc96f9f0fe1a568253452a3f40f5e9f59': {
      name: 'CryptoHoots Steampunk Parliament',
    },
    '0x986aea67c7d6a15036e18678065eb663fc5be883': {
      name: 'Nifty League DEGENs',
    },
    '0x8754f54074400ce745a7ceddc928fb1b7e985ed6': {
      name: 'EulerBeats Genesis',
    },
    '0x9759226b2f8ddeff81583e244ef3bd13aaa7e4a1': {
      name: 'Purrnelopes Country Club',
    },
    '0xbd4455da5929d5639ee098abfaa3241e9ae111af': {
      name: 'NFT Worlds',
    },
    '0xdb55584e5104505a6b38776ee4dcba7dd6bb25fe': {
      name: 'VOID - Visitors of Imma Degen',
    },
    '0x11739d7bd793543a6e83bd7d8601fcbcde04e798': {
      name: 'Wrapped Strikers',
    },
    '0x905b180268f2773022e1a10e204b0858b2e60dcf': {
      name: 'Pulsquares by Arihz',
    },
    '0x60f3680350f65beb2752788cb48abfce84a4759e': {
      name: 'Colorglyphs',
    },
    '0x30cdac3871c41a63767247c8d1a2de59f5714e78': {
      name: 'Obits Official',
    },
    '0xa3b7cee4e082183e69a03fc03476f28b12c545a7': {
      name: 'Chill Frogs NFT',
    },
    '0x3eacf2d8ce91b35c048c6ac6ec36341aae002fb9': {
      name: 'GOATz',
    },
    '0x7deb7bce4d360ebe68278dee6054b882aa62d19c': {
      name: 'MetaHero Universe: Planet Tokens',
    },
    '0x91673149ffae3274b32997288395d07a8213e41f': {
      name: 'JunkYardDogs',
    },
    '0xc36cf0cfcb5d905b8b513860db0cfe63f6cf9f5c': {
      name: 'Mirandus',
    },
    '0x12f28e2106ce8fd8464885b80ea865e98b465149': {
      name: 'BEEPLE - GENESIS COLLECTION',
    },
    '0x36d02dcd463dfd71e4a07d8aa946742da94e8d3a': {
      name: 'SympathyForTheDevils',
    },
    '0x4581649af66bccaee81eebae3ddc0511fe4c5312': {
      name: 'The Alien Boy',
    },
    '0xe15e9c0bf6b6b29d3b9e1c921ab2cb09c2194463': {
      name: 'MegaCryptoPolis',
    },
    '0xf3ae416615a4b7c0920ca32c2dfebf73d9d61514': {
      name: 'Chiptos',
    },
    '0xa98771a46dcb34b34cdad5355718f8a97c8e603e': {
      name: 'EulerBeats Enigma',
    },
    '0x819e58e51d64ab05efa132a133de0af5089954cf': {
      name: 'Brave Frontier Heroes',
    },
    '0x82262bfba3e25816b4c720f1070a71c7c16a8fc4': {
      name: 'Solvency by Ezra Miller',
    },
    '0x7dd04448c6cd405345d03529bff9749fd89f8f4f': {
      name: 'Crypto Pills by Micha Klein',
    },
    '0x9928a8ea82d86290dfd1920e126b3872890525b3': {
      name: 'Ethermon',
    },
    '0xa74e199990ff572a320508547ab7f44ea51e6f28': {
      name: 'CryptoBeasts',
    },
    '0x73883743dd9894bd2d43e975465b50df8d3af3b2': {
      name: 'Doge Pound Puppies',
    },
    '0xf75140376d246d8b1e5b8a48e3f00772468b3c0c': {
      name: 'uwucrew',
    },
    '0x370108cf39555e561353b20ecf1eaae89beb72ce': {
      name: 'Borpacasso',
    },
    '0xd668a2e001f3385b8bbc5a8682ac3c0d83c19122': {
      name: 'KingFrogs',
    },
    '0xcd1dbc840e1222a445be7c1d8ecb900f9d930695': {
      name: 'RTFKT x JeffStaple',
    },
    '0x943cc300dd938d8490d33d794507fedc25c49002': {
      name: 'unicornsmoved',
    },
    '0x67cbbb366a51fff9ad869d027e496ba49f5f6d55': {
      name: 'CryptoSpells',
    },
    '0x2a2ad5a2eacf35194d39afe1d727c39710ff0379': {
      name: 'ChainGuardians',
    },
    '0xea6504ba9ec2352133e6a194bb35ad4b1a3b68e7': {
      name: 'FusionApes',
    },
    '0x31d4da52c12542ac3d6aadba5ed26a3a563a86dc': {
      name: 'Fly Frogs',
    },
    '0xdecc60000ba66700a009b8f9f7d82676b5cfa88a': {
      name: 'The Settlements (Legacy)',
    },
    '0xbd275ce24f32d6ce4e9d9519c55abe9bc0ed7fcf': {
      name: 'BYOPills',
    },
    '0xad6dc35442d766f87f9296f17ba45e23518bc5f3': {
      name: 'Paladin Pandas',
    },
    '0x0427743df720801825a5c82e0582b1e915e0f750': {
      name: '0xmons',
    },
    '0xe4cfae3aa41115cb94cff39bb5dbae8bd0ea9d41': {
      name: 'COVIDPunks',
    },
    '0x96ed81c7f4406eff359e27bff6325dc3c9e042bd': {
      name: 'Weird Whales',
    },
    '0xc2f05fd9fe4cfe8f7395ddb220c84d3ef240cf3d': {
      name: 'Tools Of Rock',
    },
    '0x7645eec8bb51862a5aa855c40971b2877dae81af': {
      name: 'LONDON GIFT by POB',
    },
    '0xa66f3bd98b4741bad68bcd7511163c6f855d2129': {
      name: 'Impermanent Digital',
    },
    '0x0972290a80333d19c6703073c3e57134a4ca0127': {
      name: 'RTFKT BONUS ITEMS',
    },
    '0x6c7b6cc55d4098400ac787c8793205d3e86c37c9': {
      name: 'JOYWORLD JOYs',
    },
    '0xe573b99ffd4df2a82ea0986870c33af4cb8a5589': {
      name: 'Chain/Saw',
    },
    '0xd4048be096f969f51fd5642a9c744ec2a7eb89fe': {
      name: 'HalloweenBears',
    },
    '0xbc17cd7f1a58bda5d6181618090813b3050416b5': {
      name: 'Framergence by Arihz',
    },
    '0x3a0335409a0becd0da1ff6e442b9b6874d4e87a1': {
      name: 'Template',
    },
    '0xc8a75cfe4a0b9362ff2e201f960549e875b10e2d': {
      name: 'Singularity by AIIV',
    },
    '0xd0f0c40fcd1598721567f140ebf8af436e7b97cf': {
      name: 'Jadu Jetpack',
    },
    '0x33a4cfc925ad40e5bb2b9b2462d7a1a5a5da4476': {
      name: 'PILLS',
    },
    '0xc051aab77edf25119b1a37740636ddbfb803c4e5': {
      name: 'Rebel Seals Club | RSC',
    },
    '0x6f0365ca2c1dd63473f898a60f878a07e0f68a26': {
      name: 'Top Dog Beach Club',
    },
    '0x026224a2940bfe258d0dbe947919b62fe321f042': {
      name: 'lobsterdao',
    },
    '0x63658cc84a5b2b969b8df9bea129a1c933e1439f': {
      name: 'KLEEE02',
    },
    '0xc4a0b1e7aa137ada8b2f911a501638088dfdd508': {
      name: 'UninterestedUnicorns',
    },
    '0x32895105b3a65da694c63a3f5f7347d5ebf07f07': {
      name: 'FUR BRAWLS - Dark Bunnies',
    },
    '0xeaa4c58427c184413b04db47889b28b5c98ebb7b': {
      name: 'NFTrees',
    },
    '0x6e96fb1f6d8cb1463e018a2cc6e09c64ed474dea': {
      name: 'Mandelbrot Set Collection',
    },
    '0x8460bb8eb1251a923a31486af9567e500fc2f43f': {
      name: 'The Lost Glitches',
    },
    '0x0b0b186841c55d8a09d53db48dc8cab9dbf4dbd6': {
      name: 'Satoshibles',
    },
    '0x529b6787c72a6c56b8baf5c758d95f99d2dfcf0d': {
      name: 'YFI',
    },
    '0x763864f1a74d748015f45f7c1181b60e62e40804': {
      name: 'Dope Shibas',
    },
    '0x345974220a845ddeceed011e8e6106b59724b661': {
      name: 'Non-Fungible Heroes',
    },
    '0xff796cbbe32b2150a4585a3791cadb213d0f35a3': {
      name: 'Gear (for Punks)',
    },
    '0xa32422dfb5bf85b2084ef299992903eb93ff52b0': {
      name: 'Animonkeys',
    },
    '0xe4597f9182ba947f7f3bf8cbc6562285751d5aee': {
      name: 'SuperFarm Genesis Series',
    },
    '0x6d05064fe99e40f1c3464e7310a23ffaded56e20': {
      name: 'AlphaBetty Doodles',
    },
    '0x1f2403da6a03f989d3588f61c9a6c6dbf94061b7': {
      name: 'cMyKatz',
    },
    '0x0cfb5d82be2b949e8fa73a656df91821e2ad99fd': {
      name: '10KTF',
    },
    '0x3a3fba79302144f06f49ffde69ce4b7f6ad4dd3d': {
      name: 'FUR BRAWLS - Light Bunnies',
    },
    '0x9c0ffc9088abeb2ea220d642218874639229fa7a': {
      name: 'Dogs Unchained',
    },
    '0xbd9071b63f25dd199079ed80b3b384d78042956b': {
      name: 'GRILLZ GANG',
    },
    '0x0333f63c8ed192ab07b70d67d18f787198c89fef': {
      name: 'VoxoDeus Minter',
    },
    '0xe12edaab53023c75473a5a011bdb729ee73545e8': {
      name: 'CHIBI DINOS',
    },
    '0xa7f767865fce8236f71adda56c60cf2e91dadc00': {
      name: 'Infinites AI',
    },
    '0xd70f41dd5875eee7fa9dd8048567bc932124a8d2': {
      name: 'DeepBlack',
    },
    '0x880644ddf208e471c6f2230d31f9027578fa6fcc': {
      name: "Jenkins the Valet: The Writer's Room",
    },
    '0xdbb163b22e839a26d2a5011841cb4430019020f9': {
      name: 'CryptoCubes',
    },
    '0xec9c519d49856fd2f8133a0741b4dbe002ce211b': {
      name: 'Bonsai by ZENFT',
    },
    '0xc0cf5b82ae2352303b2ea02c3be88e23f2594171': {
      name: 'The Fungible by Pak',
    },
    '0x71b11ac923c967cd5998f23f6dae0d779a6ac8af': {
      name: 'Astro Frens',
    },
    '0x548c407d35cdd3c812458d9ef6d135963f9f7ece': {
      name: 'Koin Games Dev Squad',
    },
    '0x103ffe3aee66a048e22821145a00c4cabc0ff05b': {
      name: 'Bad Bunnies NFT',
    },
    '0x2106c00ac7da0a3430ae667879139e832307aeaa': {
      name: 'Loopy Donuts',
    },
    '0x4222b2a98daa443c6a0a761300d7d6bfd9161e52': {
      name: 'Illuvium',
    },
    '0x6e9da81ce622fb65abf6a8d8040e460ff2543add': {
      name: 'Gutter Dogs',
    },
    '0xa0c38108bbb0f5f2fb46a2019d7314cce38f3f22': {
      name: 'ArcadeNFT',
    },
    '0x15533781a650f0c34f587cdb60965cdfd16ff624': {
      name: 'BlockchainBikers',
    },
    '0xecf7ef42b57ee37a959bf507183c5dd6bf182081': {
      name: 'Josie',
    },
    '0xc49a9ab342b6ea66792d4110e9ca0ab36e3a5674': {
      name: 'Chibi Apes',
    },
    '0xb32979486938aa9694bfc898f35dbed459f44424': {
      name: 'Nyan Cat (Official)',
    },
    '0xa9fdb3f96fae7c12d70393659867c6115683ada0': {
      name: 'CryptoFoxes',
    },
    '0xa5b6109ad2d35191b3bc32c00e4526be56fe321f': {
      name: 'Urbit ID',
    },
    '0x2e734269c869bda3ea6550f510d2514f2d66de71': {
      name: 'StrongBlock NFTs',
    },
    '0xf198046ed564a0ce6785eb5f4ce205cb8194efe2': {
      name: 'NFP',
    },
    '0xe81175546f554ca6ceb63b142f27de7557c5bf62': {
      name: 'Lunar Moon Plots',
    },
    '0xfc007068c862e69213dc7aa817063b1803d4e941': {
      name: 'MOONDOGS ODYSSEY',
    },
    '0x85bc2e8aaad5dbc347db49ea45d95486279ed918': {
      name: 'Cometh Spaceships',
    },
    '0xa0f38233688bb578c0a88102a95b846c18bc0ba7': {
      name: 'Great Ape Society',
    },
    '0x88091012eedf8dba59d08e27ed7b22008f5d6fe5': {
      name: 'Secret Society of Whales',
    },
    '0xae3d8d68b4f6c3ee784b2b0669885a315ba77c08': {
      name: 'RTFKT PUNK PROJECT GEN 1',
    },
    '0xf32e1bde889ecf672ffae863721c8f7d280f853b': {
      name: 'HappyLand Gummy Bears Official',
    },
    '0xcfbc9103362aec4ce3089f155c2da2eea1cb7602': {
      name: 'CryptoCrystal',
    },
    '0x4721d66937b16274fac603509e9d61c5372ff220': {
      name: 'Fast Food Frens Collection',
    },
    '0x743f80dc76f862a27598140196cc610006b2be68': {
      name: 'Shabu Town Shibas',
    },
    '0xf55dd62034b534e71cb17ef6e2bb112e93d6131a': {
      name: 'HIDDEN IN NOISE',
    },
    '0x25741f5f2dbdde9cfd38f73be7052ec03b0e9d85': {
      name: 'Neon District',
    },
    '0xb2e4e69527d57fa108c535721c057075a7a82e86': {
      name: 'Magic Mushroom Clubhouse',
    },
    '0x7afe30cb3e53dba6801aa0ea647a0ecea7cbe18d': {
      name: 'Realms (for Adventurers)',
    },
    '0xb12f78434ae7d12ae548c51a5cb734ecc4536594': {
      name: 'sLoot (Loot for Scammers)',
    },
    '0x3f0785095a660fee131eebcd5aa243e529c21786': {
      name: 'Super Yeti',
    },
    '0x27b4bc90fbe56f02ef50f2e2f79d7813aa8941a7': {
      name: 'Dego Finance',
    },
    '0x335eeef8e93a7a757d9e7912044d9cd264e2b2d8': {
      name: 'Sad Girls Bar',
    },
    '0xaae71bbbaa359be0d81d5cbc9b1e88a8b7c58a94': {
      name: '8 BIT UNIVERSE',
    },
    '0x233a65b06ef83ccf2fd58832086dd782f9da1642': {
      name: 'Evolved Apes Inc',
    },
    '0xabefbc9fd2f806065b4f3c237d4b59d9a97bcac7': {
      name: 'Zora',
    },
    '0x15c2b137e59620552bd0d712fe7279cf1f47468d': {
      name: 'Glue Factory Show',
    },
    '0x350b4cdd07cc5836e30086b993d27983465ec014': {
      name: 'Bastard Penguins',
    },
    '0x91f7bb6900d65d004a659f34205beafc3b4e136c': {
      name: 'Derpy Birbs',
    },
    '0x5f47079d0e45d95f5d5167a480b695883c4e47d9': {
      name: 'Non-Fungible Fungi Genesis',
    },
    '0xa6794dec66df7d8b69752956df1b28ca93f77cd7': {
      name: 'Savage Droids',
    },
    '0xd1e5b0ff1287aa9f9a268759062e4ab08b9dacbe': {
      name: 'Unstoppable Domains',
    },
    '0x1a2f71468f656e97c2f86541e57189f59951efe7': {
      name: 'CryptoMories',
    },
    '0xe54891774eed9277236bac10d82788aee0aed313': {
      name: 'Aavegotchi Wearable Vouchers',
    },
    '0xb0cf7da8dc482997525be8488b9cad4f44315422': {
      name: 'thedudes',
    },
    '0xf1ef40f5aea5d1501c1b8bcd216cf305764fca40': {
      name: 'Heroes of Evermore',
    },
    '0x3b3ee1931dc30c1957379fac9aba94d1c48a5405': {
      name: 'Foundation (FND)',
    },
    '0x99c2546aebc070fb1f286a346ec4d25e70533474': {
      name: 'Quadrums',
    },
    '0x75bd294f5adae8428ddcd1431ae2e0d4f5a05707': {
      name: 'The Divine Order Of the Zodiac',
    },
    '0xcfff4c8c0df0e2431977eba7df3d3de857f4b76e': {
      name: 'Rivermen',
    },
    '0xde17efac631d766e509c8bb77fc9811b4a3c0750': {
      name: 'ZombieCats',
    },
    '0xf3dfbe887d81c442557f7a59e3a0aecf5e39f6aa': {
      name: 'Treasure Project',
    },
    '0x1cda6ba9bbaeade9078a1cf60afccce0343261a2': {
      name: 'MINDDS',
    },
    '0x6e5a65b5f9dd7b1b08ff212e210dcd642de0db8b': {
      name: 'OctoHedz',
    },
    '0x46ee7947cd4fc601b4ab39d14bad260806d33a87': {
      name: "Ethernity's Master Collection",
    },
    '0x52607cb9c342821ea41ad265b9bb6a23bea49468': {
      name: 'Flowtys',
    },
    '0x3146dd9c200421a9c7d7b67bd1b75ba3e2c15310': {
      name: 'Moose Trax',
    },
    '0xa64c5ec80784675bf289a4722a2eca180212f9db': {
      name: 'Wicked Hound Bone Club',
    },
    '0x6cc462bc49cecfe943bc4f477b23b92906e6074f': {
      name: 'League of Kingdoms (Ethereum)',
    },
    '0xd0e7bc3f1efc5f098534bce73589835b8273b9a0': {
      name: 'Wrapped CryptoCats Official',
    },
    '0xe12a2a0fb3fb5089a498386a734df7060c1693b8': {
      name: 'Crypto Hodlers NFT',
    },
    '0x3c2754c0cdc5499df1a50d608d8985070bf87b30': {
      name: 'Wrapped Curio Cards',
    },
    '0xa56a4f2b9807311ac401c6afba695d3b0c31079d': {
      name: 'Monster Blocks - Official',
    },
    '0xf78296dfcf01a2612c2c847f68ad925801eeed80': {
      name: 'My Fucking Pickle',
    },
    '0x57e9a39ae8ec404c08f88740a9e6e306f50c937f': {
      name: 'Polka City Asset',
    },
    '0xe3f92992bb4f0f0d173623a52b2922d65172601d': {
      name: 'Knights of Degen - Knights!',
    },
    '0x099a16f0414cb0cc0555d5f1f8140166462d39ff': {
      name: 'UnStackedToadz',
    },
    '0x082903f4e94c5e10a2b116a4284940a36afaed63': {
      name: 'Pixls - The Awkward NFT Town',
    },
    '0xeaf546758e6e1ebcaf0b1d7e0bac5b7262c73f00': {
      name: 'Spirit Orb Pets v0',
    },
    '0xa45ef87f51b84dbd0d5b003d288b16d3d044fe6f': {
      name: 'Dirtybird Flight Club',
    },
    '0x48c58b8496642bc4c860c7efc13813b73aa674f7': {
      name: 'Super Shiba Club',
    },
    '0x5b29d4c6de73fa99ea53172689b7f62c99408e8d': {
      name: 'GenX by HOK',
    },
    '0xef9c21e3ba31a74910fc7e7cb3fc814ad842ad6e': {
      name: 'Warriors of Aradena',
    },
    '0xb80fbf6cdb49c33dc6ae4ca11af8ac47b0b4c0f3': {
      name: 'BlockArt',
    },
    '0xbb3d13260b3f6893ace34a4284be70eccf4cc0f1': {
      name: 'Long Neckie Ladies',
    },
    '0x004f5683e183908d0f6b688239e3e2d5bbb066ca': {
      name: 'Hall Of Fame Goat Lodge',
    },
    '0xc541fc1aa62384ab7994268883f80ef92aac6399': {
      name: 'RTFKT Capsule Space Drip 1.2',
    },
    '0x444467738cf0c5bcca9c1d6f66670f4c493e53ff': {
      name: 'FoxyFam',
    },
    '0xd5a73977e6effc252a7f1af4548ba11ff7187616': {
      name: 'MultiverseVM',
    },
    '0xfb3765e0e7ac73e736566af913fa58c3cfd686b7': {
      name: 'Audioglyphs',
    },
    '0xfb9e9e7150ccebfe42d58de1989c5283d0eaab2e': {
      name: 'Degenz',
    },
    '0x127e479ac59a1ea76afdedf830fecc2909aa4cae': {
      name: 'Avarik Saga Universe',
    },
    '0x83b7261db8c795701c6fc86d1fcd073ece940e10': {
      name: 'nightkids',
    },
    '0x06f8b41b72c04b2bba587fc7b09dbfb877ca7d04': {
      name: 'BlankFace',
    },
    '0x65ba44b4bf75850748e4002347fb67944ad7840d': {
      name: 'Unravelling: Void Attractors',
    },
    '0x14f03368b43e3a3d27d45f84fabd61cc07ea5da3': {
      name: 'Lucky Maneki',
    },
    '0xc17030798a6d6e1a76382cf8f439182eb0342d93': {
      name: 'SALT V4',
    },
    '0x91047abf3cab8da5a9515c8750ab33b4f1560a7a': {
      name: 'ChainFaces',
    },
    '0x312d09d1160316a0946503391b3d1bcbc583181b': {
      name: 'Slumdoge Billionaires',
    },
    '0xb894e12536486141158bc26684bdef85ea90379a': {
      name: 'BULLSEUM',
    },
    '0x11111111384122718f7a44d48290bb70a3a9f793': {
      name: 'CryptoPochi',
    },
    '0xdfe3ac769b2d8e382cb86143e0b0b497e1ed5447': {
      name: 'THE PLUTO ALLIANCE',
    },
    '0x23c54ac322cba66eddcf95c5697b53fc3a8a608c': {
      name: 'CanineCartel',
    },
    '0x682575b74a00ff87f74fc5f83c34220de50102ba': {
      name: 'e9Art',
    },
    '0xe4a3cbfa0b27db2def20bfba80905515b0855e54': {
      name: 'Wanna Panda',
    },
    '0xd43529990f3fba41affd66c4a8ab6c1b7292d9dc': {
      name: 'Golden Token Crypto New Yorkers',
    },
    '0x51e613727fdd2e0b91b51c3e5427e9440a7957e4': {
      name: 'Crypto Corgis',
    },
    '0x2d6e10561b320c4f31a903bf0fa92a1ed58637c0': {
      name: 'War Riders',
    },
    '0x4242422677fdd7ca7f638b57afbe04371d318d19': {
      name: 'AI42 Loops',
    },
    '0x74bb71a4210e33256885def483fd4227b7f9d88f': {
      name: 'Fomoverse',
    },
    '0x51ae5e2533854495f6c587865af64119db8f59b4': {
      name: 'PunkScapes',
    },
    '0x9c008a22d71b6182029b694b0311486e4c0e53db': {
      name: 'Apymon Revolution',
    },
    '0x549d38f104ac46d856c1b2bf2a20d170efdb2a8d': {
      name: 'Lonely Frog Lambo Club',
    },
    '0xe9be55ffedb6c2a2f3f8eac31c60d7f122f79958': {
      name: 'trevorjonesart',
    },
    '0xe7191c896d59a9c39965e16c5184c44172ec9cf9': {
      name: 'The Soldiers Of The Metaverse',
    },
    '0x5351105753bdbc3baa908a0c04f1468535749c3d': {
      name: 'RUDE BOYS',
    },
    '0xc86ca2bb9c9c9c9f140d832de00bfa9e153fa1e3': {
      name: 'Cyber Curated',
    },
    '0xfc778be06c9a58f8f3e5e99216efbb28f750bc98': {
      name: 'ETHEREALS WTF',
    },
    '0x7c99dedb1dd8b9c9b12a8043c569988c3f881550': {
      name: 'Vegas City',
    },
    '0x3b3bc9b1dd9f3c8716fff083947b8769e2ff9781': {
      name: 'Arabian Camels',
    },
    '0xc7e5e9434f4a71e6db978bd65b4d61d3593e5f27': {
      name: 'Alpaca City',
    },
    '0x094634cf5ec4b34b7f47bcb2c03c1c9f48e1ece8': {
      name: 'Stryking',
    },
    '0xcd4d337554862f9bc9ffffb67465b7d643e4e3ad': {
      name: 'Role for Metaverse',
    },
    '0xc2b3daf1b51238bd13afcef6e713813e6c2574e8': {
      name: 'Northern Guilds - Guild of Thor',
    },
    '0x33825285eb66c11237cc68cc182c1e9bf01ba00b': {
      name: 'The Glitches NFT',
    },
    '0x6e4c6d9b0930073e958abd2aba516b885260b8ff': {
      name: 'Influence Asteroids',
    },
    '0x2252d401ec9d16065069529b053b105fe42e0176': {
      name: 'Ciphersquares Official',
    },
    '0xd49eccf40689095ad9e8334d8407f037e2cf5e42': {
      name: 'The Evolving Forest Genesis',
    },
    '0x5e86f887ff9676a58f25a6e057b7a6b8d65e1874': {
      name: 'Bitchcoin',
    },
    '0x4ebdd33ca183a8a5f82c43831df5147ea0e16b93': {
      name: 'Lysergic Labs Shroomz',
    },
    '0x06a6a7af298129e3a2ab396c9c06f91d3c54aba8': {
      name: '0xUniverse: Galaxy Home (Ethereum)',
    },
    '0x138ff21a21dfc06fbfccf15f2d9fd290a660e152': {
      name: 'Based Fish Mafia',
    },
    '0x5e34dacda29837f2f220d3d1aeaaabd1edca5bd1': {
      name: 'Spookies NFT',
    },
    '0x730151b8286ca0508a19a9d5c47fe91fdd9a6737': {
      name: 'Nifty Island: Legendary Palms',
    },
    '0x5c400511fb292a50b4d81e601815f617db804302': {
      name: 'Superfuzz The Good Guys',
    },
    '0xfd69bce04ee5979c24f72504569c0f399c4b4624': {
      name: 'Tropical Turtles',
    },
    '0xdf6e32d85d17e907e0da157fab7c12788e7161da': {
      name: 'Kohi Kintsugi',
    },
    '0x36f379400de6c6bcdf4408b282f8b685c56adc60': {
      name: 'Squiggly',
    },
    '0xdaa5f6cd0d1ae382a67e8a9b1ddff08685e443bc': {
      name: '[HT] FUSION',
    },
    '0x7c10bc61de6781caa00f2f6d8eb4822684337a2b': {
      name: 'ApeIn.Finance Shop',
    },
    '0x031920cc2d9f5c10b444fd44009cd64f829e7be2': {
      name: 'Zunks',
    },
    '0x25c65721e26fa5f3c97f129f4e24972482327bc9': {
      name: 'CryptoMutts',
    },
    '0xc196ae96170c58eb7b78c917298064ce7192f046': {
      name: 'Holy Cows',
    },
    '0x4fece400c0d3db0937162ab44bab34445626ecfe': {
      name: 'ETH TOWN',
    },
    '0xb1469271ff094d7fb2710b0a69a80a01ec5dbf24': {
      name: 'Deez Nuts (Official Nuts)',
    },
    '0xb2b42942133130e09d54d9712c3f7e1b54d088cb': {
      name: 'GRILLZ GANG OG BADGE',
    },
    '0xcab65c60d9dc47e1d450c7e9074f73f1ff75f181': {
      name: 'Dogs Unchained - Proof of Steak',
    },
    '0xf1b33ac32dbc6617f7267a349be6ebb004feccff': {
      name: 'Dreamloops',
    },
    '0x455f1e8fa7e99c61c29728cdf7cfb3d4654d11b9': {
      name: 'Curse NFT Tickets',
    },
    '0x07f7c1fb71a4b3d50f6146d13b53f115afb83236': {
      name: 'BYOKEY',
    },
    '0x8479277aacff4663aa4241085a7e27934a0b0840': {
      name: 'Realms of Ether',
    },
    '0x1c5ed03149b1fd5efe12828a660c7b892c111ba4': {
      name: 'WE ARE THE OUTKAST',
    },
    '0x87d598064c736dd0c712d329afcfaa0ccc1921a1': {
      name: 'CryptoFighters',
    },
    '0x2013ff029f9d3d31169f4fdc673c8b9fa0ce1fbd': {
      name: 'TinyPaws',
    },
    '0x478059f577cb739f0af0f37a365dd069ba5bd8be': {
      name: 'Crypto stamp',
    },
    '0xe80201a8e706a7ac353124c004960201c8b99f4b': {
      name: 'Infinites IRL',
    },
    '0x30d5977e4c9b159c2d44461868bdc7353c2e425b': {
      name: 'Land DAO',
    },
    '0xc729ce9bf1030fbb639849a96fa8bbd013680b64': {
      name: 'Monarchs.Art',
    },
    '0x066f2d5ead7951f0d0038c19affd500b9f02c0e5': {
      name: 'Janky Heist',
    },
    '0xb396b8699f728735f28a31279d07b1d0d2411baa': {
      name: 'Hackatao',
    },
    '0x950b9476a4de757bb134483029ac4ec17e739e3a': {
      name: 'Gutter Pigeons',
    },
    '0x87b5d8fe6cf8b37678b6cef7cca23a4852883c48': {
      name: '888 Inner Circle The Drops',
    },
    '0xa2d544196fdbc9da15a701c38bb60513c1802ef4': {
      name: 'FEWOCiOUS',
    },
    '0xb28a4fde7b6c3eb0c914d7b4d3ddb4544c3bcbd6': {
      name: 'Skvllpvnkz Hideout',
    },
    '0xf90aeef57ae8bc85fe8d40a3f4a45042f4258c67': {
      name: 'The SURF Shop',
    },
    '0xfd3fd9b793bac60e7f0a9b9fb759db3e250383cb': {
      name: 'Ethereans Official',
    },
    '0x163a7af239b409e79a32fc6b437fda51dd8fa5f0': {
      name: 'Roaring Leaders',
    },
    '0xd2d2a84f0eb587f70e181a0c4b252c2c053f80cb': {
      name: 'Toshimon',
    },
    '0x44d6e8933f8271abcf253c72f9ed7e0e4c0323b3': {
      name: 'DRM - Dont Rug Me - Cryptocurrency CCG Official Cards',
    },
    '0x97e41d5ce9c8cb1f83947ef82a86e345aed673f3': {
      name: 'The Ninja Hideout',
    },
    '0x61598e2e6b012293cb6012b055ad77aa020e0206': {
      name: 'BroadcastersNFT',
    },
    '0x26b925eef82525f514c0414db5cf65953d30a4ca': {
      name: 'Genesis Blocks Art',
    },
    '0xdf055660b7314a061688108eb14a185081377b0e': {
      name: 'Mad Cat Militia Cattery',
    },
    '0x02aa731631c6d7f8241d74f906f5b51724ab98f8': {
      name: 'BearsOnTheBlock',
    },
    '0x30a2fa3c93fb9f93d1efeffd350c6a6bb62ba000': {
      name: 'CryptoMotors',
    },
    '0x31d4c5be1082a88f2abafea549b6c189c2cf057f': {
      name: 'Aether',
    },
    '0x9930929903f9c6c83d9e7c70d058d03c376a8337': {
      name: 'RTFKT Creators',
    },
    '0x1eb7382976077f92cf25c27cc3b900a274fd0012': {
      name: 'Bored Ape Comic',
    },
    '0xf4b6040a4b1b30f1d1691699a8f3bf957b03e463': {
      name: 'Genesis Mana (for Loot)',
    },
    '0xd81f156bbf7043a22d4ce97c0e8ca11d3f4fb3cc': {
      name: 'Torn Maps',
    },
    '0x0b1f901eedfa11e7cf65efeb566eeffb6d38fbc0': {
      name: 'Saconi Holmovimiento',
    },
    '0x672c1f1c978b8fd1e9ae18e25d0e55176824989c': {
      name: 'SpaceBoysNFT',
    },
    '0x9619dabdc2eb3679943b51afbc134dec31b74fe8': {
      name: 'Ethermore',
    },
    '0x3290f349a0642229b46b7102d2024b34fe8bd3cc': {
      name: 'TheDiamondHands',
    },
    '0xd949a444f29ede73f517942eb4d8e3ccdab25d58': {
      name: 'Mad Dog Jones',
    },
    '0xa5ae87b40076745895bb7387011ca8de5fde37e0': {
      name: 'Bubblegum Kids',
    },
    '0x329fd5e0d9aad262b13ca07c87d001bec716ed39': {
      name: 'Adventure Cards',
    },
    '0x613e5136a22206837d12ef7a85f7de2825de1334': {
      name: 'Untamed Elephants',
    },
    '0xc59c53995f923b8fb22f6487bf5b706327376d67': {
      name: 'Non Fungible Frens',
    },
    '0x9abb7bddc43fa67c76a62d8c016513827f59be1b': {
      name: 'POW NFT',
    },
    '0x35368c20f70766d528afa8c91b1b47e2735cc7fc': {
      name: 'MarbleCards',
    },
    '0xd33bc0af2dc4e39cbaef4beff8d1fb3c00c2e7a3': {
      name: "ACK Unique Editions / 1 of 1's",
    },
    '0xd46814b736db3c6feed576a59e3ff140075c9e0a': {
      name: 'Dark Horizon',
    },
    '0xd87ca9651cdade60cc0b031c2731aeb5aa722fb3': {
      name: 'EtherGals',
    },
    '0x57fbb364041d860995ed610579d70727ac51e470': {
      name: 'Mad Rabbits Riot Club',
    },
    '0x5219c2f6f8ed1e76c937ed1269eda2658ba3c721': {
      name: 'The Club721',
    },
    '0x172700a7dbbf92ee1db1474f956fd1078d2d0a00': {
      name: 'Choadz',
    },
    '0x4e2781e3ad94b2dfcf34c51de0d8e9358c69f296': {
      name: "Sora's DreamworId",
    },
    '0x3d0c4e9bde15f2681902f9f291b3c905b7ea46f9': {
      name: 'Gener8tive K-Compositions',
    },
    '0xafba8c6b3875868a90e5055e791213258a9fe7a7': {
      name: 'VoxoDeus',
    },
    '0x2a281305a50627a22ec3e7d82ae656adfee6d964': {
      name: 'Barn Owlz',
    },
    '0x0825f050e9b021a0e9de8cb1fb10b6c9f41e834c': {
      name: 'LetsWalk',
    },
    '0xa4d5fb4ff0fa1565fb7d8f5db88e4c0f2f445046': {
      name: 'Notorious Frogs',
    },
    '0x882a47e6070aca3f38ce6929501f4787803a072b': {
      name: 'Dizzy Dragons',
    },
    '0x062e691c2054de82f28008a8ccc6d7a1c8ce060d': {
      name: 'Pudgy Presents',
    },
    '0xff1be3171a16fe431e31d874e4de14814362e588': {
      name: 'Ethermonkeys',
    },
    '0xbe1014903e2f623a88e4413e276fb176e6e832de': {
      name: 'Tokenmon',
    },
    '0xeb113c5d09bfc3a7b27a75da4432fb3484f90c6a': {
      name: 'Kinesis Art',
    },
    '0xe18a32192ed95b0fe9d70d19e5025f103475d7ba': {
      name: 'HASH by POB',
    },
    '0x42a87e04f87a038774fb39c0a61681e7e859937b': {
      name: 'Ability Score',
    },
    '0xc92d06c74a26aeaf4d1a1273fac171f3b09fac79': {
      name: 'DAO Turtles',
    },
    '0xf65d8f4a5c7956f40be2eae8171d62a6c97fab9c': {
      name: 'WavesOnChain',
    },
    '0x47ccad36ae77ab963746c8db8ad301d48235ce81': {
      name: 'PROOF OF {ART}WORK',
    },
    '0x7f0ab6a57cfd191a202ab3f813ef9b851c77e618': {
      name: 'Land DAO Genesis',
    },
    '0x901682fe39416e61526203a11d212e3329d07e92': {
      name: 'Gamete',
    },
    '0x3a921bf2c96cb60d49aff09110a9f836c295713a': {
      name: 'Terra Nullius V2',
    },
    '0x2a46f2ffd99e19a89476e2f62270e0a35bbf0756': {
      name: 'MakersPlace',
    },
    '0x62cdaf466511888792c8c413239baa70f57f1654': {
      name: 'Guardians of the Metaverse',
    },
    '0x8056ad118916db0feef1c8b82744fa37e5d57cc0': {
      name: 'Krazy Koalas NFT',
    },
    '0xd8682bfa6918b0174f287b888e765b9a1b4dc9c3': {
      name: 'The Moon Boyz',
    },
    '0x0d8c864da1985525e0af0acbeef6562881827bd5': {
      name: 'CheezeWizard',
    },
    '0x2fb5d7dda4f1f20f974a0fdd547c38674e8d940c': {
      name: 'KnightStory',
    },
    '0x7403ac30de7309a0bf019cda8eec034a5507cbb3': {
      name: 'Characters  (for Adventurers)',
    },
    '0x518ba36f1ca6dfe3bb1b098b8dd0444030e79d9f': {
      name: 'Wonky Stonks',
    },
    '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e': {
      name: 'Doodles',
    },
    '0x918f677b3ab4b9290ca96a95430fd228b2d84817': {
      name: 'Lil Baby Ape Club',
    },
    '0x60f80121c31a0d46b5279700f9df786054aa5ee5': {
      name: 'Rarible',
    },
    '0xfeb52cbf71b9adac957c6f948a6cf9980ac8c907': {
      name: 'Decentraland Wearables',
    },
    '0x128675d4fddbc4a0d3f8aa777d8ee0fb8b427c2f': {
      name: 'PUNKS Comic',
    },
    '0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b': {
      name: 'CLONE X - X TAKASHI MURAKAMI',
    },
    '0xa5f1ea7df861952863df2e8d1312f7305dabf215': {
      name: 'ZED RUN',
    },
    '0x2a378c8d96e7d994fb9bec6adb7c6af2fe772c3b': {
      name: 'Somnium Space VR',
    },
    '0x348fc118bcc65a92dc033a951af153d14d945312': {
      name: 'RTFKT - CloneX Mintvial',
    },
    '0x79986af15539de2db9a5086382daeda917a9cf0c': {
      name: 'Cryptovoxels',
    },
    '0xeb834ae72b30866af20a6ce5440fa598bfad3a42': {
      name: 'Wolf Game',
    },
    '0x28472a58a490c5e09a238847f66a68a47cc76f0f': {
      name: 'adidas Originals Into the Metaverse',
    },
    '0xf76179bb0924ba7da8e7b7fc2779495d7a7939d8': {
      name: 'VOX Collectibles: Mirandus',
    },
    '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85': {
      name: 'ENS: Ethereum Name Service',
    },
    '0x999e88075692bcee3dbc07e7e64cd32f39a1d3ab': {
      name: 'Wizards & Dragons Game (WnD)',
    },
    '0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e': {
      name: 'Desperate ApeWives',
    },
    '0x9bf252f97891b907f002f2887eff9246e3054080': {
      name: 'Ape Kids Club (AKC)',
    },
    '0x698fbaaca64944376e2cdc4cad86eaa91362cf54': {
      name: 'NeoTokyo Outer Identities',
    },
    '0x97597002980134bea46250aa0510c9b90d87a587': {
      name: 'Chain Runners',
    },
    '0x469823c7b84264d1bafbcd6010e9cdf1cac305a3': {
      name: 'Crypto Bull Society',
    },
    '0xab0b0dd7e4eab0f9e31a539074a03f1c1be80879': {
      name: 'Neo Tokyo Part 2 Vault Cards',
    },
    '0x0b4b2ba334f476c8f41bfe52a428d6891755554d': {
      name: 'JRNY CLUB OFFICIAL',
    },
    '0x7f36182dee28c45de6072a34d29855bae76dbe2f': {
      name: 'Wolf Game - Migrated',
    },
    '0x5df340b5d1618c543ac81837da1c2d2b17b3b5d8': {
      name: 'Party Ape Billionaire Club',
    },
    '0xa342f5d851e866e18ff98f351f2c6637f4478db5': {
      name: 'Fantasy Islands - Sandbox',
    },
    '0xf54cc94f1f2f5de012b6aa51f1e7ebdc43ef5afc': {
      name: 'Project NANOPASS',
    },
    '0x45db714f24f5a313569c41683047f1d49e78ba07': {
      name: 'SpacePunksClub',
    },
    '0xf62c6a8e7bcdc96cda11bd765b40afa9ffc19ab9': {
      name: 'HeadDAO',
    },
    '0xd92e44ac213b9ebda0178e1523cc0ce177b7fa96': {
      name: 'BEEPLE: EVERYDAYS - THE 2020 COLLECTION',
    },
    '0x4cd0ea8b1bdb5ab9249d96ccf3d8a0d3ada2bc76': {
      name: 'Boonji Project',
    },
    '0x059edd72cd353df5106d2b9cc5ab83a52287ac3a': {
      name: 'Construction Token by Jeff Davis',
    },
    '0xc6ec80029cd2aa4b0021ceb11248c07b25d2de34': {
      name: 'the littles NFT',
    },
    '0xa1d4657e0e6507d5a94d06da93e94dc7c8c44b51': {
      name: 'Worldwide Webb Land',
    },
    '0x26437d312fb36bdd7ac9f322a6d4ccfe0c4fa313': {
      name: 'ASM AIFA Genesis',
    },
    '0x09233d553058c2f42ba751c87816a8e9fae7ef10': {
      name: 'My Pet Hooligan',
    },
    '0x4be3223f8708ca6b30d1e8b8926cf281ec83e770': {
      name: 'Party Degenerates',
    },
    '0xd692ced124a474f051f9744a301c26d1017b3d54': {
      name: 'Shiba Social Club Official Collection',
    },
    '0x5fdb2b0c56afa73b8ca2228e6ab92be90325961d': {
      name: 'Slotie NFT',
    },
    '0x3ca5b00ade54365fbd590d4bc397e044a13068e5': {
      name: 'Feudalz',
    },
    '0x95784f7b5c8849b0104eaf5d13d6341d8cc40750': {
      name: 'Swampverse',
    },
    '0x0938e3f7ac6d7f674fed551c93f363109bda3af9': {
      name: 'Neo Tokyo Part 3 Item Caches',
    },
    '0x8634666ba15ada4bbc83b9dbf285f73d9e46e4c2': {
      name: 'Chicken Derby',
    },
    '0xf0bd260fcf279f3138726016b8a03c7110364e04': {
      name: 'PolkaPets TCG',
    },
    '0x6d4530149e5b4483d2f7e60449c02570531a0751': {
      name: 'NFTBoxes',
    },
    '0x177ef8787ceb5d4596b6f011df08c86eb84380dc': {
      name: 'Smilesssvrs ::)',
    },
    '0xc631164b6cb1340b5123c9162f8558c866de1926': {
      name: 'Divine Anarchy',
    },
    'No items to display ': {
      name: 'The Million Dollar Punk Draw',
    },
    '0x96316355c44be69414756d6706c61e61aecbd5f3': {
      name: 'Cosmic Labs',
    },
    '0xf3114dd5c5b50a573e66596563d15a630ed359b4': {
      name: 'Fat Ape Club',
    },
    '0x4cff01dbed00a5e95d705f96acf369f210c203c8': {
      name: 'Noundles',
    },
    '0x368ad4a7a7f49b8fa8f34476be0fc4d04ce622f5': {
      name: 'OG:Crystals',
    },
    '0xa4631a191044096834ce65d1ee86b16b171d8080': {
      name: 'CreatureToadz',
    },
    '0xe47e90c58f8336a2f24bcd9bcb530e2e02e1e8ae': {
      name: 'DSC MATES | DIGITAL IDENTITY',
    },
    '0xc2d6b32e533e7a8da404abb13790a5a2f606ad75': {
      name: 'Revenants by Alethea AI',
    },
    '0x75e95ba5997eb235f40ecf8347cdb11f18ff640b': {
      name: 'Psychedelics Anonymous Genesis',
    },
    '0x0322f6f11a94cfb1b5b6e95e059d8deb2bf17d6a': {
      name: 'CryptoonGoonz',
    },
    '0xe27a60f3cf27f716ac998b61492a36090973aac7': {
      name: 'Bear Game NFT',
    },
    '0x7eef591a6cc0403b9652e98e88476fe1bf31ddeb': {
      name: 'CityDAO Citizenship',
    },
    '0xc6c817cd60e17fed0af2a759624e02dd6c812e64': {
      name: 'METABILLIONAIRE',
    },
    '0x7cae7b9b9a235d1d94102598e1f23310a0618914': {
      name: 'CROAKZ by McGill!',
    },
    '0x79fcdef22feed20eddacbb2587640e45491b757f': {
      name: 'mfers',
    },
    '0x2c88aa0956bc9813505d73575f653f69ada60923': {
      name: 'Wolf Game - Genesis Land',
    },
    '0x5bdf397bb2912859dbd8011f320a222f79a28d2e': {
      name: 'Corruption(s*)',
    },
    '0xa5c0bd78d1667c13bfb403e2a3336871396713c5': {
      name: "Coolman's Universe",
    },
    '0x49907029e80de1cbb3a46fd44247bf8ba8b5f12f': {
      name: 'More Than Gamers | MTG',
    },
    '0x2953399124f0cbb46d2cbacd8a89cf0599974963': {
      name: 'Big Time Founders Collection',
    },
    '0x9d77cb4d8371736e2a2b2bfaa677b7841cdc8fc1': {
      name: 'DeRace Horses',
    },
    '0x3abedba3052845ce3f57818032bfa747cded3fca': {
      name: 'Ether Orcs',
    },
    '0x21bf3da0cf0f28da27169239102e26d3d46956e5': {
      name: 'Monaco Planet Yacht',
    },
    '0x3c54b798b3aad4f6089533af3bdbd6ce233019bb': {
      name: 'Neo Tokyo Part 4 Land Deeds',
    },
    '0x3db5463a9e2d04334192c6f2dd4b72def4751a61': {
      name: 'ALPACADABRAZ',
    },
    '0x9eeeaf684e228c2d5c89435e010acc02c41dc86b': {
      name: 'Bricktopians by Law Degree',
    },
    '0xb184b9414e7d7c436b7097ed2c774bb56fae392f': {
      name: 'Society of Derivative Apes (SODA)',
    },
    '0x6aac4b68638dc65083e7d1c9cc0af0781a98baf1': {
      name: 'Fox Game Official',
    },
    '0xe22e1e620dffb03065cd77db0162249c0c91bf01': {
      name: 'BearXLabs',
    },
    '0x65c234d041f9ef96e2f126263727dfa582206d82': {
      name: 'Playboy Rabbitars Official',
    },
    '0xe7e16f2da731265778f87cb8d7850e31b84b7b86': {
      name: 'Ember Sword Land',
    },
    '0x4e1f41613c9084fdb9e34e11fae9412427480e56': {
      name: 'Terraforms by Mathcastles',
    },
    '0xf06059f01f6f08a603c939359a0a1186f7687685': {
      name: 'MegaCryptoPolis',
    },
    '0xc2e9678a71e50e5aed036e00e9c5caeb1ac5987d': {
      name: 'Wilder World',
    },
    '0x4fe99deb8f0be517c31faaad49b4b054ed28c267': {
      name: 'ChainGuardians',
    },
    '0x86758d1c5ab95ff324f7e0b2cdec38c86eebe768': {
      name: 'iNFT Personality Pod Sale',
    },
    '0xeda3b617646b5fc8c9c696e0356390128ce900f8': {
      name: 'Jadu Hoverboard',
    },
    '0xc143bbfcdbdbed6d454803804752a064a622c1f3': {
      name: 'Async Blueprints',
    },
    '0xf9c362cdd6eeba080dd87845e88512aa0a18c615': {
      name: 'Meta Legends',
    },
    '0xa67d63e68715dcf9b65e45e5118b5fcd1e554b5f': {
      name: 'Pepsi Mic Drop',
    },
    '0x338866f8ba75bb9d7a00502e11b099a2636c2c18': {
      name: 'Women and Weapons',
    },
    '0x0ffa87cd27ae121b10b3f044dda4d28f9fb8f079': {
      name: 'Sidus NFT Heroes',
    },
    '0x0beed7099af7514ccedf642cfea435731176fb02': {
      name: 'DuskBreakers',
    },
    '0xdfde78d2baec499fe18f2be74b6c287eed9511d7': {
      name: 'BrainDrops',
    },
    '0xa2a13ce1824f3916fc84c65e559391fc6674e6e8': {
      name: 'Crypto Unicorns Market',
    },
    '0xa16891897378a82e9f0ad44a705b292c9753538c': {
      name: 'PILLS',
    },
    '0xfd1076d80fff9dc702ae9fdfea0073467b9b3fb7': {
      name: 'Spooky Boys Country Club | by HolyGhost',
    },
    '0x68930072647a0d79e601d5ee3914833ae9148247': {
      name: 'Wavelength by Kaleb Johnston',
    },
    '0xe6ef513f7429d92cb54ebd4c14026aeb90849a78': {
      name: "Impact Theory Founder's Key",
    },
    '0x7daec605e9e2a1717326eedfd660601e2753a057': {
      name: '10KTF Stockroom',
    },
    '0x2aeaffc99cef9f6fc0869c1f16f890abdfcc222b': {
      name: 'League of Kingdoms (Ethereum)',
    },
    '0x33e1977d6593050520b1fe2d5c586376ad07046d': {
      name: 'BRAiN VOMiTS GARDEN',
    },
    '0x97a923ed35351a1382e6bcbb5239fc8d93360085': {
      name: 'Crypto Champions Collection',
    },
    '0x01fe2358cc2ca3379cb5ed11442e85881997f22c': {
      name: 'Cosmic Wyverns Official',
    },
    '0x134460d32fc66a6d84487c20dcd9fdcf92316017': {
      name: 'Woodies Generative Characters',
    },
    '0x7a3b97a7400e44dadd929431a3640e4fc47daebd': {
      name: 'Apes In Space NFT',
    },
    '0x1ebb218415b1f70aeff54041c743082f183318ce': {
      name: 'Whales.Game',
    },
    '0x66fca7555cd481545a5e66ba9a2bec1e256f98e7': {
      name: 'Gold Hunt Game',
    },
    '0x77640cf3f89a4f1b5ca3a1e5c87f3f5b12ebf87e': {
      name: 'Angry Ape Army',
    },
    '0xa4f709db1f2b5f78d5f44ea5f30e430193b532c3': {
      name: 'Genesis Curry Flow',
    },
    '0x0aa7420c43b8c1a7b165d216948870c8ecfe1ee1': {
      name: 'Thingdoms',
    },
    '0x838804a3dd7c717396a68f94e736eaf76b911632': {
      name: 'ZenApe',
    },
    '0x3987c4c9b78a5869df994c6e83a53d24baaa1b07': {
      name: 'Frank Miller: Sin City 30th Anniversary',
    },
    '0xdebbc3691d42090d899cafe0c4ed80897a7c9d6a': {
      name: 'Fluffy Polar Bears',
    },
    '0x2d004b72d8b7d36f9da2e4a14516618bf53bac57': {
      name: 'Furballs.com Official',
    },
    '0x62d8ae32b0e6964d2126716cf3da70c2bd062d94': {
      name: 'The Alien Secret Society',
    },
    '0x123b30e25973fecd8354dd5f41cc45a3065ef88c': {
      name: 'alien frens',
    },
    '0x2a187453064356c898cae034eaed119e1663acb8': {
      name: 'Decentraland Names',
    },
    '0x8ed0e7404675d5c7f5b4f2a829138afcaf53d2ab': {
      name: 'X-Consoles by 0xnft',
    },
    '0xbcd4f1ecff4318e7a0c791c7728f3830db506c71': {
      name: 'Cometh Spaceships',
    },
  },
  GA_MEASUREMENT_ID: 'G-LG8279HEC1',
};

export default AppConfig;
