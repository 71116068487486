import { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';

// Import Icons
import LossIcon from '../assets/images/icons/loss.svg';
import ProfitIcon from '../assets/images/icons/profit.svg';
import ToiletHand from '../assets/images/icons/toilet_hand@2x.png';
import EthIcon from '../assets/images/icons/eth-icon.png';
import GasIcon from '../assets/images/icons/gas-icon.png';
import { ICollectionObj } from '../api/types/collection';
import FilterKey from '../config/Constant';

interface Props {
  collectionList: Array<ICollectionObj>;
  filterKeyChangeHandler: (value: string) => void;
  ethUsdtPrice: number | null;
  gasPrice: string | null;
  filterKey: string;
  isAscSort: boolean;
}

const PriceRangeTableComponent: FunctionComponent<Props> = ({
  collectionList,
  filterKeyChangeHandler,
  ethUsdtPrice,
  gasPrice,
  filterKey,
  isAscSort,
}: Props) => {
  const getProfitOrLossHtml = (collectionObj: ICollectionObj, isFloorChange: boolean) => {
    let returnHtml = <></>;
    let image = ProfitIcon;
    let value = 0.0;
    let colorClass = 'text-green';
    if (isFloorChange) {
      if (collectionObj['24HrFloorChangePercent'] >= 0) {
        colorClass = 'text-green';
        image = ProfitIcon;
      } else {
        colorClass = 'text-red';
        image = LossIcon;
      }
      value = collectionObj['24HrFloorChangePercent'];
    } else {
      if (collectionObj.percentDelisting >= 0) {
        colorClass = 'text-green';
        image = ProfitIcon;
      } else {
        colorClass = 'text-red';
        image = LossIcon;
      }
      value = collectionObj.percentDelisting;
    }

    returnHtml = (
      <div className={`d-flex align-items-center justify-content-center ${colorClass}`}>
        <span className="size-12">
          <img src={image} alt="Profit" className="img-fluid" />
        </span>
        {value}%
      </div>
    );

    return returnHtml;
  };

  return (
    <div className="new-content">
      <div className="new-content-wrap sm:flex-col md:flex-col">
        <div className="w-full d-flex align-items-center justify-content-between mb-30 sm:flex-col">
          <div className="d-flex align-items-center">
            <span className="size-10">
              <img src={ToiletHand} alt="Toilet Hand" className="img-fluid" />
            </span>
            <span className="text-upper fs-15 nowrap">Top collections BY paperhands.gg</span>
          </div>

          <div className="ml-auto d-flex align-items-center max-w200 sm:mr-auto sm:mt-14">
            {ethUsdtPrice && (
              <div className="d-flex align-items-center mr-3">
                <span className="size-10">
                  <img src={EthIcon} alt="ETH Price" className="img-fluid" />
                </span>
                <span className="text-upper fs-15 nowrap font-vcr">
                  <NumberFormat value={ethUsdtPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </span>
              </div>
            )}
            {gasPrice && (
              <div className="d-flex align-items-center">
                <span className="size-10">
                  <img src={GasIcon} alt="Gas Price" className="img-fluid" />
                </span>
                <span className="text-upper fs-15 nowrap font-vcr">{gasPrice}</span>
              </div>
            )}
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="hidden-xs"></th>
                <th scope="col" className="logo-column"></th>
                <th
                  scope="col"
                  className={`${
                    filterKey === FilterKey.collectionName ? (isAscSort ? `sorting-asc` : `sorting-desc`) : `sorting`
                  }`}
                  onClick={() => filterKeyChangeHandler(FilterKey.collectionName)}
                >
                  Name
                </th>
                <th
                  scope="col"
                  className={`${
                    filterKey === FilterKey.HrVol ? (isAscSort ? `sorting-asc` : `sorting-desc`) : `sorting`
                  }`}
                  onClick={() => filterKeyChangeHandler(FilterKey.HrVol)}
                >
                  24H Vol
                </th>
                <th
                  scope="col"
                  className={`${
                    filterKey === FilterKey.HrSales ? (isAscSort ? `sorting-asc` : `sorting-desc`) : `sorting`
                  }`}
                  onClick={() => filterKeyChangeHandler(FilterKey.HrSales)}
                >
                  24H Sales
                </th>
                <th
                  scope="col"
                  className={`${
                    filterKey === FilterKey.floorPrice ? (isAscSort ? `sorting-asc` : `sorting-desc`) : `sorting`
                  }`}
                  onClick={() => filterKeyChangeHandler(FilterKey.floorPrice)}
                >
                  Floor
                </th>
                <th
                  scope="col"
                  className={`${
                    filterKey === FilterKey.HrFloorChangePercent
                      ? isAscSort
                        ? `sorting-asc`
                        : `sorting-desc`
                      : `sorting`
                  }`}
                  onClick={() => filterKeyChangeHandler(FilterKey.HrFloorChangePercent)}
                >
                  24H Floor Change
                </th>
                <th
                  scope="col"
                  className={`${
                    filterKey === FilterKey.totalListed ? (isAscSort ? `sorting-asc` : `sorting-desc`) : `sorting`
                  }`}
                  onClick={() => filterKeyChangeHandler(FilterKey.totalListed)}
                >
                  # Listed
                </th>
                <th
                  scope="col"
                  className={`${
                    filterKey === FilterKey.percentDelisting ? (isAscSort ? `sorting-asc` : `sorting-desc`) : `sorting`
                  }`}
                  onClick={() => filterKeyChangeHandler(FilterKey.percentDelisting)}
                >
                  1D Delistings
                </th>
                <th
                  scope="col"
                  className={`${
                    filterKey === FilterKey.percentSupplyListed
                      ? isAscSort
                        ? `sorting-asc`
                        : `sorting-desc`
                      : `sorting`
                  }`}
                  onClick={() => filterKeyChangeHandler(FilterKey.percentSupplyListed)}
                >
                  % Supply Listed
                </th>
              </tr>
            </thead>
            <tbody>
              {collectionList.length > 0 ? (
                collectionList.map((listObj, key) => (
                  <tr key={key}>
                    <td className="hidden-xs">{key + 1}</td>
                    <td className="logo-column">
                      <span className="size-24">
                        <img src={listObj.collectionImageUrl} alt="Profit" className="img-fluid" />
                      </span>
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <span className="name">{listObj.collectionName}</span>
                    </td>
                    <td>{listObj['24HrVol']}Ξ</td>
                    <td>{listObj['24HrSales']}</td>
                    <td>{listObj.floorPrice}Ξ</td>
                    <td>{getProfitOrLossHtml(listObj, true)}</td>
                    <td>{listObj.totalListed}</td>
                    <td>{getProfitOrLossHtml(listObj, false)}</td>
                    <td>{listObj.percentSupplyListed}%</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    No data available...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PriceRangeTableComponent;
