const FilterKey = {
  collectionName: 'collectionName',
  HrVol: '24HrVol',
  HrSales: '24HrSales',
  floorPrice: 'floorPrice',
  HrFloorChangePercent: '24HrFloorChangePercent',
  totalListed: 'totalListed',
  percentDelisting: 'percentDelisting',
  percentSupplyListed: 'percentSupplyListed',
};

export default FilterKey;
