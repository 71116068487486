const development = {
  apiGateway: {
    BASE_URL: 'https://apiuat.paperhands.gg',
  },
};

const uat = {
  apiGateway: {
    BASE_URL: 'https://apiuat.paperhands.gg',
  },
};

const production = {
  apiGateway: {
    BASE_URL: 'https://api.paperhands.gg',
  },
};

let baseConfig =
  process.env.REACT_APP_STAGE === 'production' ? production : process.env.REACT_APP_STAGE === 'uat' ? uat : development;

const AppConfig = {
  REACT_APP_STAGE: process.env.REACT_APP_STAGE,
  REACT_APP_VERSION: 1,
  ...baseConfig,
};

export default AppConfig;
